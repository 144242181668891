import Button from 'components/atoms/Button';
import { TypesNamesEnum } from 'enums/Button.enum';
import React, { FC } from 'react';
import styled from 'styled-components';
import RadioButton from 'components/atoms/RadioButton/RadioButton';

interface Props {
  next: () => void;
  back: () => void;
  loading: boolean;
  accreditedValue: [number, number];
  setAccreditedValue: (accreditedValue: [number, number]) => void;
}

const intervals = [[0, 50000], [50000, 100000], [100000, 100000]]
const RegisterAccreditedValue: FC<Props> = ({ next, accreditedValue, setAccreditedValue, back, loading }) => {

  const getLabel = ([first, _]: [number, number]) => {
    if(first === 0) return '<$50K'
    if(first === 50000) return '$50K to $100K'
    if(first === 100000) return '>$100K'
    return ''
  }

  const items = intervals.map((value, index) => ({
    id: index.toString(),
    label: getLabel(value as [number, number]),
    value: value as [number, number]
  }))

  return (
    <AccreditedValueBox>
      <p>What is the value of assets you are planning to invest with us?</p>
      <RadioGroup>
        {items.map(item => {
          return (<RadioButton
            key={'accreditedValue-' + item.id}
            size={30}
            name='investmentRange'
            text={getLabel(item.value)}
            value={item.value[0].toString()}
            checked={accreditedValue[0] === item.value[0]}
            onChange={() => setAccreditedValue(item.value)}
          />);
        })}
      </RadioGroup>
      <Grid>
        <Button buttonType={TypesNamesEnum.SECONDAY} disabled={loading} label='Back' onClick={back} type='button' />
        <Button buttonType={TypesNamesEnum.ACCENT} disabled={loading} isLoading={loading} label='Next' onClick={next} type='submit' />
      </Grid>
    </AccreditedValueBox>
  );
};

const AccreditedValueBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Grid = styled.div`
  display: grid;
  gap: 10px;
  height: 50px;
  margin-top: 50px;
  grid-template-columns: 1fr 1fr;
  Button {
    font-weight: 400;
    font-size: 1.1rem;
  }
`;

const RadioGroup = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 2vh;
  margin-top: 0.5vh;
`;

export default RegisterAccreditedValue;
