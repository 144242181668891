import React, { useCallback, useState } from "react";
import Button from "components/atoms/Button";
import { Text } from "components/atoms/Typography";
import { SizeNamesEnum, TypesNamesEnum } from "enums/Button.enum";
import { palette } from "lib/theme";
import { useDispatch, useSelector } from "react-redux";
import { TwoFaModalHeader } from "./TwoFaModalHeader";
import { getText } from "shared/locale-helper";
import {
  useLoginMutation,
  useReset2FaMutation,
} from "state/store/api";
import {
  saveTokenToValidate,
  selectCurrentUser,
} from "state/slice/auth.slice";
import Input from "components/atoms/Input";
import Turnstile from 'react-turnstile';
import { toast, ToastType } from "components/organisms/Toast";
import styled from "styled-components";

const TURNSTILE_SITE_KEY = process.env.TURNSTILE_SITE_KEY || '';
const TURNSTILE_ENABLED = process.env.TURNSTILE_ENABLED !== 'false';

interface ISharedKeyCode {
  sharedKey: string;
  currentStep: string;
  change2FaFlow: boolean;
  setCurrentStep: (step: string) => void;
  setSharedKey: (key: string) => void;
  setShowTwoFAModal: (modal: boolean) => void;
  setChange2FaFlow: (secondFlow: boolean) => void;
  setHeader2FASuccess: (headerText: boolean) => void;
}

export const SharedConfirmPassword = ({
  sharedKey,
  currentStep,
  change2FaFlow,
  setCurrentStep,
  setShowTwoFAModal,
}: ISharedKeyCode) => {
  const user = useSelector(selectCurrentUser);

  const [reset2fa, { isLoading: reset2faLoading }] = useReset2FaMutation();
  const [login, { isLoading: loginLoading }] = useLoginMutation();

  const [password, setPassword] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<
    string | undefined
  >(undefined);

  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const [turnstileToken, setTurnstileToken] = useState('');
  
  const handleTurnstileVerify = (token: string) => {
    setTurnstileToken(token);
  };

  const handleTurnstileError = () => {
    setTurnstileToken('');
    toast.show({
      type: ToastType.Fail,
      title: "Verification failed",
      content: "Please try the verification again",
    });
  };

  const validatePassword = useCallback(() => {
    if (password) {
      setPasswordErrorMessage(undefined);
    } else {
      setPasswordErrorMessage(getText("Password is required"));
    }

    return !!!password;
  }, [password]);

  const handleEnableKey = async (code: string) => {
    try {
      if (TURNSTILE_ENABLED && !turnstileToken) {
        toast.show({
          type: ToastType.Fail,
          title: "Verification required",
          content: "Please complete the verification challenge",
        });
        return;
      }

      await reset2fa({
        usernameAndPasswordAuthenticationRequest: {
          username: user?.email,
          password,
        },
      }).unwrap();

      const loginResponse = await login({
        usernameAndPasswordAuthenticationRequest: {
          username: user?.email,
          password: password,
          token: turnstileToken
        },
      }).unwrap();

      dispatch(saveTokenToValidate(loginResponse));

      setCurrentStep("SharedKeyInfo");
    } catch (error) {
      setErrorMessage(
        "Something's off. Double-check the code or request a new one."
      );
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <TwoFaModalHeader
        sharedKey={sharedKey}
        change2FaFlow={change2FaFlow}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        setShowTwoFAModal={setShowTwoFAModal}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingTop: "63px",
        }}
      >
        <Text
          color={palette.darkBackgroundContrast.light70}
          letterSpacing="-0.5"
          align="center"
        >
          Enter your current password
        </Text>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingTop: "24.5px",
        }}
      >
        <Input
          label={"Password"}
          className="input"
          errorMessage={passwordErrorMessage}
          showError={false}
          dismissAlert={() => setPasswordErrorMessage("")}
          dismissAlertButton={false}
          inputProps={{
            value: password,
            id: "password",
            name: "password",
            type: "password",
            onChange: (e: any) => setPassword(e?.target?.value),
            onBlur: (e: any) => {
              setPasswordErrorMessage("");
              if (e?.target?.value) {
                validatePassword();
              }
            },
            style: {
              marginBottom: "-14px",
            },
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingBottom: "15px",
          flex: 1,
        }}
      ></div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Button
          dataCyPrefix="profile_walletsecurity_change2fa"
          buttonType={TypesNamesEnum.ACCENT}
          size={SizeNamesEnum.EXTRA_LARGE_PLUS}
          label="Confirm"
          isLoading={reset2faLoading || loginLoading}
          onClick={() => {
            handleEnableKey(password);
          }}
        ></Button>
        <Text
          display="block"
          size="small"
          color={palette.darkBackgroundContrast.light70}
        >
          Can't access the authenticator app?{" "}
          <Text
            size="small"
            color={palette.accent.light}
            onClick={() => window.open("https://support.abra.com")}
          >
            Contact Customer Support <br />
          </Text>{" "}
          to change 2FA.
        </Text>
        {TURNSTILE_ENABLED && (
          <TurnstileWrapper>
            <Turnstile
              sitekey={TURNSTILE_SITE_KEY}
              onVerify={handleTurnstileVerify}
              onError={handleTurnstileError}
              onExpire={() => setTurnstileToken('')}
              theme="light"
              refreshExpired="auto"
            />
          </TurnstileWrapper>
        )}
      </div>
    </div>
  );
};

const TurnstileWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px 0;
`;