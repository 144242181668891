import React from 'react';
import { Routes, Route, unstable_HistoryRouter as HistoryRouter, Navigate } from 'react-router-dom';

import { PagesUrl } from 'lib/constants/config.constant';
import history from 'shared/history';
import ProtectedRoutes from 'shared/ProtectedRoute';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'state/slice/auth.slice';

// Authentication
import Authentication from 'pages/Authentication';

import Boost from 'pages/Boost/Boost';
import ProfilePage from 'pages/ProfileScreen';
import TransactionScreen from 'pages/TransactionScreen';
import DepositDetails from 'pages/DepositDetails/DepositDetails';
import LoanScreen from 'pages/LoanScreen';
import Borrow from 'pages/Borrow/Borrow';
import Withdraw from 'pages/Withdraw/Withdraw';
import WhitelistedAddresses from 'pages/WhitelistedAddresses/WhitelistedAddresses';
import Custody from 'components/pages/custody/Custody'
import CustodyDeposit from 'components/pages/custody/CustodyDeposit'
import CustodyWithdraw from 'components/pages/custody/CustodyWithdraw'
import CustodyDocuments from 'components/pages/custody/CustodyDocuments';
import CustodyStatement from 'components/pages/custody/document/CustodyStatement';
import CustodyInvoice from 'components/pages/custody/document/CustodyInvoice';
import InvestmentMainPage from 'components/pages/custody/invest/InvestmentMainPage'
import InvestmentStrategiesSummary from 'components/pages/custody/invest/InvestmentStrategiesSummary'
import DivestActionPage from 'components/pages/custody/invest/DivestActionPage'
import InvestStrategySelected from 'components/pages/custody/invest/InvestStrategySelected'
import InvestSelectStrategy from 'components/pages/custody/invest/InvestSelectStrategy'
import RegisterPage from 'pages/Register/RegisterPage'
import RedirectFromOnboardingEmail from 'pages/Register/RedirectFromOnboardingEmail'
import RedirectKYC from 'pages/Register/RedirectKYC'
import HelloSignScreen from 'pages/Register/HelloSignScreen'
import SetupCredentials from 'pages/Register/SetupCredentials'
import KYCFailurePage from 'pages/Register/KYCFailurePage'
import Disclaimer from 'components/disclaimer/Disclaimer'
import FiatMainPage from 'components/pages/custody/fiat/FiatMainPage';
import TradeMainPage from 'components/pages/custody/trade/TradeMainPage';
import LoanMainPage from 'components/pages/custody/loan/LoanMainPage';
import DefiBorrow from 'pages/DefiBorrow/DefiBorrow'
import CustodyIncome from 'components/pages/custody/document/income/CustodyIncome';
import CustodyGainLoss from 'components/pages/custody/document/gainloss/CustodyGainLoss';
import CustodyExpenses from 'components/pages/custody/document/expenses/CustodyExpenses';
import FiatWithdraw from 'components/pages/custody/withdrawal/FiatWithdraw';

const App = () => {
  const user = useSelector(selectCurrentUser);

  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route path={`${PagesUrl.ONBOARDING}`} element={<RedirectFromOnboardingEmail />}/>
        <Route path={`${PagesUrl.ONBOARDING}/kyc`} element={<RedirectKYC />}/>
        <Route path={`${PagesUrl.ONBOARDING}/kyc/failure`} element={<KYCFailurePage />}/>
        <Route path={`${PagesUrl.ONBOARDING}/sign`} element={<HelloSignScreen />}/>
        <Route path={`${PagesUrl.ONBOARDING}/password`} element={<SetupCredentials />}/>
        <Route path={`${PagesUrl.AUTHENTICATION}/*`} element={<Authentication />} />
        <Route path='/' element={<Authentication />} />
        <Route path={`/disclaimer`} element={<Disclaimer />}/>
        <Route path='/register' element={<RegisterPage />} />
        <Route path='/setup-credentials' element={<RegisterPage />} />
        <Route path={PagesUrl.HOME} element={<ProtectedRoutes />}>
          <Route path={`${PagesUrl.WITHDRAW}/:orderId`} element={<Withdraw />} />
          <Route path={`${PagesUrl.CUSTODY}`} element={<Custody />} />
          <Route path={`${PagesUrl.CUSTODY}/deposit`} element={<CustodyDeposit />} />
          <Route path={`${PagesUrl.CUSTODY}/deposit-fiat`} element={<FiatMainPage />} />
          <Route path={`${PagesUrl.CUSTODY}/deposit/:asset`} element={<CustodyDeposit />} />
          <Route path={`${PagesUrl.CUSTODY}/withdraw`} element={<CustodyWithdraw />} />
          <Route path={`${PagesUrl.CUSTODY}/withdraw-fiat`} element={<FiatWithdraw />} />
          <Route path={`${PagesUrl.CUSTODY}/withdraw/:asset`} element={<CustodyWithdraw />} />
          <Route path={`${PagesUrl.CUSTODY}/invest`} element={<InvestmentMainPage />} />
          <Route path={`${PagesUrl.CUSTODY}/invest/:strategyIdentifier`} element={<InvestStrategySelected />} />
          <Route path={`${PagesUrl.CUSTODY}/invest/asset/:asset`} element={<InvestSelectStrategy />} />
          <Route path={`${PagesUrl.CUSTODY}/divest/:strategyIdentifier`} element={<DivestActionPage />} />
          <Route path={`${PagesUrl.CUSTODY}/invest/details/:strategyIdentifier`} element={<InvestmentStrategiesSummary />} />
          <Route path={`${PagesUrl.CUSTODY + PagesUrl.DOCUMENT}`} element={<CustodyDocuments />} />
          <Route path={`${PagesUrl.CUSTODY + PagesUrl.DOCUMENT + PagesUrl.STATEMENT}/:year/:month`} element={<CustodyStatement />} />
          <Route path={`${PagesUrl.CUSTODY + PagesUrl.DOCUMENT + PagesUrl.INVOICE}/:year/:month`} element={<CustodyInvoice />} />
          <Route path={`${PagesUrl.CUSTODY + PagesUrl.DOCUMENT + PagesUrl.INCOME}/:year`} element={<CustodyIncome />} />
          <Route path={`${PagesUrl.CUSTODY + PagesUrl.DOCUMENT + PagesUrl.GAIN_LOSS}/:year`} element={<CustodyGainLoss />} />
          <Route path={`${PagesUrl.CUSTODY + PagesUrl.DOCUMENT + PagesUrl.EXPENSES}/:year`} element={<CustodyExpenses />} />
          <Route path={`${PagesUrl.ADDRESSES}`} element={<WhitelistedAddresses />} />
          <Route path={`${PagesUrl.ADDRESSES}/:assetName/:withdrawId`} element={<WhitelistedAddresses />} />
          <Route path={PagesUrl.PROFILE} element={<ProfilePage />} />
          <Route element={<Boost />} />
          <Route path={PagesUrl.BOOST} element={<Boost />} />
          <Route path={PagesUrl.DEFI_BORROW} element={<DefiBorrow />} />
          <Route path={`${PagesUrl.BOOST}/:depositId${PagesUrl.DEPOSIT_DETAILS}`} element={<DepositDetails />} />
          <Route path={`${PagesUrl.BOOST}${PagesUrl.TRANSACTION}/:type`} element={<TransactionScreen />} />
          <Route index={user?.organization?.products?.includes('Borrow')} path={PagesUrl.BORROW} element={<Borrow />} />

          <Route path={`${PagesUrl.BORROW}${PagesUrl.TRANSACTION}/:type`} element={<TransactionScreen />} />
          <Route path={`${PagesUrl.BORROW}/:orderId${PagesUrl.LOAN_DETAILS}`} element={<LoanScreen />} />
          <Route path='*' element={<Navigate to={PagesUrl.PROFILE} />} />
          <Route path={`${PagesUrl.CUSTODY}/trade`} element={<TradeMainPage />} />
          <Route path={`${PagesUrl.CUSTODY}/loan`} element={<LoanMainPage />} />

        </Route>
      </Routes>
    </HistoryRouter>
  );
};

export default App;
