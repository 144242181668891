import { useCallback, useState } from 'react';
import {
    useGetDefiPendingTransactionsQuery,
    useGetDefiStrategyBalanceQuery,
    useGetDefiStrategyDetailsQuery,
    useLazyGetInvestTransactionsInfoQuery,
    useGetDefiTransactionsQuery,
    useLazyGetDivestTransactionsInfoQuery,
} from 'state/store/investApi';

const useStrategy = (strategyIdentifier: string) => {
    const strategyBalance = useGetDefiStrategyBalanceQuery(strategyIdentifier);
    const strategyDetails = useGetDefiStrategyDetailsQuery(strategyIdentifier);
    const [transactionsPage, setTransactionsPage] = useState(1);
    const defiTransactions = useGetDefiTransactionsQuery({
        page: transactionsPage,
        strategyIdentifier,
    });
    const [fetchInvestInfo, investInfo] =
        useLazyGetInvestTransactionsInfoQuery();
    const [fetchDivestInfo, divestInfo] =
        useLazyGetDivestTransactionsInfoQuery();
    const pendingTransactions =
        useGetDefiPendingTransactionsQuery(strategyIdentifier);

    const loadInvestTransactionsInfo = useCallback(async () => {
        try {
            const result = await fetchInvestInfo(
                strategyIdentifier ?? ''
            ).unwrap();
            return result;
        } catch (error) {
            console.error('Failed to fetch transactions info:', error);
            throw error;
        }
    }, [fetchInvestInfo, strategyIdentifier]);

    const loadDivestTransactionInfo = useCallback(async () => {
        try {
            const result = await fetchDivestInfo(
                strategyIdentifier ?? ''
            ).unwrap();
            return result;
        } catch (error) {
            console.error('Failed to fetch transactions info:', error);
            throw error;
        }
    }, [fetchDivestInfo, strategyIdentifier]);

    return {
        strategyBalance,
        strategyDetails,
        defiTransactions,
        pendingTransactions,
        transactionsPage,
        setTransactionsPage,
        investInfo,
        loadInvestTransactionsInfo,
        divestInfo,
        loadDivestTransactionInfo,
    };
};

export default useStrategy;
