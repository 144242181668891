import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Turnstile from 'react-turnstile';

import { TypesNamesEnum } from '@types/enums/Button.enum';
import { symbolsValidation } from 'shared/helper';
import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Link from 'components/atoms/Link';
import EmailUs from 'components/atoms/EmailUs/EmailUs';
import { emailValidation } from 'lib/utils/validations';
import { PagesUrl } from 'lib/constants/config.constant';
import { getText } from 'shared/locale-helper';
import mParticle from '@mparticle/web-sdk';
import { useLoginMutation } from 'state/store/api';
import { logout, saveTokenToValidate, setCredentials } from 'state/slice/auth.slice';
import { toast, ToastType } from 'components/organisms/Toast';

const TURNSTILE_SITE_KEY = process.env.TURNSTILE_SITE_KEY;
const TURNSTILE_ENABLED = process.env.TURNSTILE_ENABLED !== 'false';

const SignInEmail = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [turnstileToken, setTurnstileToken] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState<string | undefined>(undefined);
  const [responseErrorMessage, setResponseErrorMessage] = useState<string | undefined>(undefined);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string | undefined>(undefined);
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();

  const [login, { isLoading }] = useLoginMutation();

  const eraseStorage = () => {
    dispatch(logout());
  };

  useEffect(() => {
    eraseStorage();
  }, []);

  const navigate = useNavigate();

  const validateEmail = useCallback(() => {
    if (!symbolsValidation('@', email)) {
      setEmailErrorMessage(getText('dont_forget_to_add_the'));
    } else if (!emailValidation(email)) {
      setEmailErrorMessage(getText('Email is invalid'));
    } else {
      setEmailErrorMessage(undefined);
    }

    return !symbolsValidation('@', email) && !emailValidation(email);
  }, [email]);

  const validatePassword = useCallback(() => {
    if (password) {
      setPasswordErrorMessage(undefined);
    } else {
      setPasswordErrorMessage(getText('Password is required'));
    }

    return !!!password;
  }, [password]);

  useEffect(() => {
    const isDisabled = emailErrorMessage !== undefined || 
                      passwordErrorMessage !== undefined || 
                      (TURNSTILE_ENABLED && !turnstileToken);
    setDisabled(isDisabled);
  }, [emailErrorMessage, passwordErrorMessage, email, password, turnstileToken]);

  const handleTurnstileVerify = (token: string) => {
    setTurnstileToken(token);
  };

  const handleTurnstileError = () => {
    setTurnstileToken('');
    toast.show({
      type: ToastType.Fail,
      title: "Verification failed",
      content: 'Please try again',
    });
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    try {
      if (!e) return;
      e.preventDefault();
      
      const emailInvalid = validateEmail();
      const passwordInvalid = validatePassword();
      
      if (emailInvalid || passwordInvalid) {
        return;
      }

      if (TURNSTILE_ENABLED && !turnstileToken) {
        toast.show({
          type: ToastType.Fail,
          title: "Verification required",
          content: 'Please complete the verification challenge',
        });
        return;
      }

      const data = await login({
        usernameAndPasswordAuthenticationRequest: {
          username: email,
          password: password,
          token: turnstileToken
        },
      }).unwrap();

      dispatch(saveTokenToValidate(data));

      if (data.otpIsActive) {
        navigate(`${PagesUrl.AUTHENTICATION}${PagesUrl.TWO_FACTOR_AUTH}`);
      } else if (!data.otpIsActive && data.barcodeUri == null) {
        dispatch(setCredentials({ accessToken: `Bearer ${data.accessToken}` }));
        navigate(PagesUrl.CUSTODY);
      } else {
        navigate(`${PagesUrl.AUTHENTICATION}${PagesUrl.ENABLE_2FA}`);
      }
    } catch (error) {
      toast.show({
        title: 'An error has occurred',
        content: 'Email or password is incorrect. Please enter a valid email or password',
        type: ToastType.Fail,
      });
    }
  };

  return (
    <div>
      <form onSubmit={async (e) => await onSubmit(e)}>
        <Input
          label='Email'
          className='input'
          errorMessage={emailErrorMessage || responseErrorMessage}
          dismissAlert={() => setEmailErrorMessage('')}
          dismissAlertButton={false}
          inputProps={{
            value: email,
            id: 'email',
            name: 'email',
            type: 'email',
            onChange: (e: any) => setEmail(e?.target?.value),
            onBlur: (e: any) => {
              setEmailErrorMessage('');
              if (e?.target?.value) {
                validateEmail();
              }
            },
          }}
        />
        <Input
          label='Password'
          className='input'
          errorMessage={passwordErrorMessage || responseErrorMessage}
          showError={false}
          dismissAlert={() => setPasswordErrorMessage('')}
          dismissAlertButton={false}
          inputProps={{
            value: password,
            id: 'password',
            name: 'password',
            type: 'password',
            onChange: (e: any) => setPassword(e?.target?.value),
            onBlur: (e: any) => {
              setPasswordErrorMessage('');
              if (e?.target?.value) {
                validatePassword();
              }
            },
          }}
        />
        <LinksArea>
          <Link
            label='Forgot password?'
            onClick={() => {
              mParticle.logEvent('screen_viewed', mParticle.EventType.Navigation, {
                screen_title: 'EmailPasswordSignin',
                cta: 'forgotpassword_button',
                email: email,
              });
              navigate(`${PagesUrl.AUTHENTICATION}${PagesUrl.RESET_PASSWORD}`);
            }}
          />
        </LinksArea>



        <div style={{ display: 'flex' }}>
          <ButtonSignIn
            buttonType={TypesNamesEnum.ACCENT}
            label={'Sign in'}
            onClick={async (e) => await onSubmit(e)}
            disabled={disabled}
            isLoading={isLoading}
            type='submit'
          />
        </div>
      </form>

      <div style={{ padding: '5vh 0px' }}>
        <EmailUs />
      </div>

      {TURNSTILE_ENABLED && (
        <TurnstileWrapper>
          <Turnstile
            sitekey={TURNSTILE_SITE_KEY}
            onVerify={handleTurnstileVerify}
            onError={handleTurnstileError}
            onExpire={() => setTurnstileToken('')}
            theme="auto"
            refreshExpired="auto"
            appearance="interaction-only"
          />
        </TurnstileWrapper>
      )}

    </div>
  );
};

export const ButtonSignIn = styled(Button)`
  flex: 1;
  padding: 15px;
`;

const LinksArea = styled.div`
  display: flex;
  gap: 50px;
  justify-content: flex-end;
  padding-bottom: 3vh;
`;

const TurnstileWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

export default SignInEmail;