import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useStrategy from './useStrategy';
import styled from 'styled-components';
import { QuestionCircle } from 'assets/icons';
import useInvest from './useInvest';
import StrategyDetailsPopup from './StrategyDetailsPopup';
import moment from 'moment'
import usePermissions from 'shared/usePermissions';

const StrategyDetailsCard = () => {
  const { strategyIdentifier } = useParams();
  const { strategyDetails, loadInvestTransactionsInfo, investInfo } = useStrategy(strategyIdentifier ?? '');
  const [showStrategyDetails, setShowStrategyDetails] = useState(false);
  const { defiBalances } = useInvest();
  const balances = defiBalances.data?.filter((d) => d.identifier === strategyIdentifier);
  const balance = balances?.length ? balances[0] : undefined;
  const navigate = useNavigate();
  const [haveContent, setHaveContent] = useState(true)
  const getIntervalText = (interval: string) => {
    const capitalizedInterval = interval.charAt(0).toUpperCase() + interval.slice(1);
    const intervalName = interval === 'day' ? 'Daily' : `${capitalizedInterval}ly`;
    return `${intervalName} (This strategy allows once a ${interval} entry and exit)`;
  };
  const { canInvest: canUserInvest, canDivest: canUserDivest } = usePermissions();

  useEffect(() => {
    if (strategyIdentifier) {
      loadInvestTransactionsInfo();
    }
  }, [strategyIdentifier, loadInvestTransactionsInfo]);

  return (
    <div>
      <Header>
        <HeaderTitle>{strategyDetails.data?.name}</HeaderTitle>
      </Header>
      <TokenSummaryTitle>Strategy Details</TokenSummaryTitle>
      <StrategyDetailsInformation>
        <div>
          {strategyDetails.data?.url && (
            <>
          <StrategyDetailsName>URL</StrategyDetailsName>
          <StrategyDetailsDescription>
            {strategyDetails?.data?.url && (
              <StrategyLink target='_blank' href={strategyDetails.data?.url}>
                  {strategyDetails.data?.url}
                </StrategyLink>
            )}
            {investInfo.data?.strategyFactSheetUrl && (
              <>
                <StrategyLink target='_blank' href={investInfo.data?.strategyFactSheetUrl}>
                  {investInfo.data?.strategyFactSheetUrl}
                </StrategyLink>
              </>
            )}
          </StrategyDetailsDescription>
          </>
          )}
        </div>
        <div>
          <StrategyDetailsName>Description</StrategyDetailsName>
          <StrategyDetailsDescription>
            {strategyDetails?.data?.description}
            {haveContent && 
            <StrategyButton onClick={() => setShowStrategyDetails(true)}>
              Learn More <QuestionCircle color='#a399f6' />
            </StrategyButton>
            }
          </StrategyDetailsDescription>
        </div>
        {strategyDetails.data?.interval && 
        <div>
          <StrategyDetailsName>Strategy Action Duration</StrategyDetailsName>
          <StrategyDetailsDescription>{getIntervalText(strategyDetails.data?.interval)}</StrategyDetailsDescription>
        </div>
        }
        {
          investInfo.data?.nextInvestmentCutoff && <>
        <div>
          <StrategyDetailsName>Next Entry Date</StrategyDetailsName>
          <StrategyDetailsDescription>{moment.utc(investInfo.data?.nextInvestmentCutoff).format('MM/DD/yyyy HH:mm:ss')} UTC<span>(You will start earning interest beginning next month)</span></StrategyDetailsDescription>
        </div>
        <div>
          <StrategyDetailsName>Next Exit Date</StrategyDetailsName>
          <StrategyDetailsDescription>{moment.utc(investInfo.data?.nextInvestmentCutoff).format('MM/DD/yyyy HH:mm:ss')} UTC<span>(You will continue to earn interest till end of the month)</span></StrategyDetailsDescription>
        </div>
          </>
        }
        {strategyDetails.data?.annualFeeBasisPoints ? (
          <div>
            <StrategyDetailsName>Abra Advisory Fee</StrategyDetailsName>
            <StrategyDetailsDescription>{strategyDetails.data?.annualFeeBasisPoints} bps</StrategyDetailsDescription>
          </div>
        ) : (
          <></>
        )}
        { (canUserInvest || canUserDivest) &&
        <div>
          
          <StrategyDetailsName>Actions</StrategyDetailsName>
          <ButtonsArea>
            {balance && balance.canInvest && canUserInvest && <ActionButton onClick={() => navigate(`/custody/invest/${strategyIdentifier}`)}>Invest</ActionButton>}
            {balance && balance.canDivest && canUserDivest && parseFloat(balance?.currentBalance?.amount ?? '0') > 0 && <ActionButton onClick={() => navigate(`/custody/divest/${strategyIdentifier}`)}>Divest</ActionButton>}
          </ButtonsArea>
        </div>}
      </StrategyDetailsInformation>
      <StrategyDetailsPopup
        url={strategyDetails.data?.url}
        show={showStrategyDetails}
        closeAction={(param) => setShowStrategyDetails(param)}
        identifier={balance?.identifier ?? ''}
        noContentTrigger={() => setHaveContent(false)}
      />
    </div>
  );
};

const ButtonsArea = styled.div`
  display: flex;
  gap: 4px;
`;
const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6f2acd;
  text-transform: uppercase;
  font-size: 70%;
  padding: 0.5vw 1vw;
  transition: background-color 0.3s ease-in;
  cursor: pointer;
  &:hover {
    background-color: #292749;
  }
`;

const TokenSummaryTitle = styled.div`
  font-weight: 600;
  color: #ffffff;
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 10px;
  letter-spacing: -0.5px;
`;

const StrategyLink = styled.a`
  color: #a399f6;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0.51px;
`;

const StrategyButton = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 6px;
  color: #a399f6;
  cursor: pointer;
  text-decoration: none;
  font-size: 18px;
  letter-spacing: 0.51px;
`;

const StrategyDetailsInformation = styled.div`
  & > div {
    display: grid;
    grid-template-columns: 150px auto;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 6px;
    align-items: center;
    padding-bottom: 6px;
  }
`;
const StrategyDetailsName = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  -webkit-letter-spacing: 0.045em;
  -moz-letter-spacing: 0.045em;
  -ms-letter-spacing: 0.045em;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  font-feature-settings: 'ss02' on, 'liga' off;
  color: rgba(255, 255, 255, 0.7);
`;
const StrategyDetailsDescription = styled.p`
  font-weight: 400;
  color: #ffffffee;
  font-size: 18px;
  display: flex;
  gap: 2ch;
  -webkit-letter-spacing: -0.48px;
  -moz-letter-spacing: -0.48px;
  -ms-letter-spacing: -0.48px;
  letter-spacing: -0.48px;
  line-height: 130%;
`;

const HeaderTitle = styled.h2`
  font-weight: 600;
  color: #ffffff;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: -0.5px;
  margin-bottom: 30px;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
export default StrategyDetailsCard;
