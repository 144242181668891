import React, { useEffect, useState, VFC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AT_LEAST_NINE_CHARACTERS, AT_LEAT_ONE_LOWERCASE_LATTER, AT_LEAT_ONE_NUMBER, AT_LEAT_ONE_UPPERCASE_LATTER } from 'lib/constants/regexp';
import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import { palette } from 'lib/theme';
import { emailValidation, emptyStringValidation, passwordComplexityValidation } from 'lib/utils/validations';
import { useDispatch } from 'react-redux';
import { getText } from 'shared/locale-helper';
import { PagesUrl } from 'lib/constants/config.constant';
import { useLoginMutation} from 'state/store/api';
import { saveTokenToValidate, setCredentials } from 'state/slice/auth.slice';
import { TypesNamesEnum } from 'enums/Button.enum';
import { toast, ToastType } from 'components/organisms/Toast';
import {
  StyleAuthenticationCarouselContainer,
  StyleAuthenticationFormContainer,
  StyledAuthenticationContainer,
  StyledForm,
  StyledFormWrapper,
  StyledHeading,
} from 'styles/components/authentication';
import styled from 'styled-components';
import { Heading } from 'components/atoms/Typography';
import Loading from 'components/atoms/Loading';
import Footer from 'components/molecules/Footer/Footer';
import { SpinnerSizeEnum } from 'components/atoms/Loading/Loading';
import useOnboarding from './useOnboarding';
import onboardingApi from 'state/store/onboardingApi';
import Turnstile from 'react-turnstile';

const TURNSTILE_SITE_KEY = process.env.TURNSTILE_SITE_KEY || '';
const TURNSTILE_ENABLED = process.env.TURNSTILE_ENABLED !== 'false';

const SetupCredentials: VFC = () => {
  const [searchParams] = useSearchParams();
  const { onboardingStatus } = useOnboarding();
  const email = searchParams.get('email') ?? '';
  const registrationId = searchParams.get('registrationId') ?? '';
  const [setupSMA] = onboardingApi.useSetupSMAAccountMutation();
  const [createAuthAccount, createAuthAccountMeta] = onboardingApi.useCreateAuthAccountMutation();

  const loading = onboardingStatus.isLoading;
  const navigate = useNavigate()
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  useEffect(() => {
    const setupSMAProcess = async () => {
      const response = await setupSMA({email, registrationId}).unwrap()
      if(response.status === 'DONE') {
        navigate('/auth/sign-in?onboarding=done')
      }
      
    }
    if(onboardingStatus.data){
      if(onboardingStatus.data.status === 'SMA_ACCOUNT_SETUP'){
        setupSMAProcess()
      } else if(onboardingStatus.data.status === 'DONE') {
        navigate('/auth/sign-in?onboarding=done')
      } else if (onboardingStatus.data.status === 'FAILED') {
        navigate('/auth/sign-in?onboarding=failed')
      }
    }
  }, [onboardingStatus, navigate, email, registrationId, setupSMA])

  const [pwdEnforce, setpwdEnforce] = useState([
    {
      text: getText('1_upper_case_letter'),
      icon: 'Tick',
      color: palette.darkBackgroundContrast.light20,
      pattern: AT_LEAT_ONE_UPPERCASE_LATTER,
    },
    {
      text: getText('9_characters'),
      icon: 'Tick',
      color: palette.darkBackgroundContrast.light20,
      pattern: AT_LEAST_NINE_CHARACTERS,
    },
    {
      text: getText('1_lower_case_letter'),
      icon: 'Tick',
      color: palette.darkBackgroundContrast.light20,
      pattern: AT_LEAT_ONE_LOWERCASE_LATTER,
    },
    {
      text: getText('1_number'),
      icon: 'Tick',
      color: palette.darkBackgroundContrast.light20,
      pattern: AT_LEAT_ONE_NUMBER,
    },
  ]);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [login, { isLoading: loginLoading }] = useLoginMutation();

  const dispatch = useDispatch();
  const [turnstileToken, setTurnstileToken] = useState('');

  const getPasswordRegexMatchMessage = (_password: any[]): string => {
    const rulesMatched = _password.find((el) => el.color === palette.darkBackgroundContrast.light20);

    return rulesMatched ? `${getText('you_re_missing')} ${rulesMatched.text}` : '';
  };

  const handleTurnstileVerify = (token: string) => {
    setTurnstileToken(token);
  };
  
  const handleTurnstileError = () => {
    setTurnstileToken('');
    toast.show({
      type: ToastType.Fail,
      title: "Verification failed",
      content: "Please try the verification again",
    });
  };

  const validateSignUp = (): number => {
    let validationCount = 0;
    setEmailError('');
    setPasswordError('');

    if (emptyStringValidation(email)) {
      validationCount += 1;
      setEmailError(getText('add_your_email_address_to_continue'));
    }

    if (emptyStringValidation(password)) {
      validationCount += 1;
      setPasswordError(getText('create_a_password_to_continue'));
    }

    if (email && !emailValidation(email)) {
      validationCount += 1;
      setEmailError(getText('hmm_that_doesnt_look_right'));
    }

    if (password && !passwordComplexityValidation(password)) {
      validationCount += 1;
      setPasswordError(getPasswordRegexMatchMessage(pwdEnforce));
    }
    return validationCount;
  };

  const handlePasswordOnChange = (event: any) => {
    const paswordValue = event.target.value;
    const patternCheck = pwdEnforce.map((item) => {
      return {
        ...item,
        color: item.pattern.exec(paswordValue) ? palette.accent.main : palette.darkBackgroundContrast.light20,
      };
    });
    setPassword(paswordValue);
    setpwdEnforce(patternCheck);
  };

  const handleOnSubmit = async () => {
    try {
      if (validateSignUp() > 0) return;

      if (TURNSTILE_ENABLED && !turnstileToken) {
        toast.show({
          type: ToastType.Fail,
          title: "Verification required",
          content: "Please complete the verification challenge",
        });
        return;
      }

      await createAuthAccount({
        email,
        password,
        registrationId,
      }).unwrap();

      const loginData = await login({
        usernameAndPasswordAuthenticationRequest: {
          username: email,
          password: password,
          token: turnstileToken
        },
      }).unwrap();

      dispatch(saveTokenToValidate(loginData));

      toast.show({
        type: ToastType.Success,
        title: 'Success',
        content: 'Your password has been set! please proceed with the two factor authentication to log in!',
      });

      if (loginData.otpIsActive) navigate(`${PagesUrl.AUTHENTICATION}${PagesUrl.TWO_FACTOR_AUTH}`);
      else if (!loginData.otpIsActive && loginData.barcodeUri == null) {
        dispatch(setCredentials({ accessToken: `Bearer ${loginData.accessToken}` }));
        navigate(PagesUrl.CUSTODY);
      } else {
        navigate(`${PagesUrl.AUTHENTICATION}${PagesUrl.ENABLE_2FA}`);
      }
    } catch (error) {}
  };

  return (
    <StyledAuthenticationContainer>
      <CarrouselContainer>
        <StyleAuthenticationCarouselContainer />
      </CarrouselContainer>
      <StyleAuthenticationFormContainer>
        <FormContainer>
          <StyledFormWrapper>
            <StyledHeading>
              <Heading variant='h2'>Secure Your Account</Heading>
            </StyledHeading>
            <StyledForm>
              {loading ? (
                <Loading showText={false} size={SpinnerSizeEnum.LARGE} />
              ) : (
                <div style={{ paddingLeft: '4px', marginTop: '3vh' }}>
                  <Input
                    label='Email'
                    className='input'
                    readOnly
                    inputProps={{
                      value: email,
                      id: 'email',
                      name: 'email',
                      type: 'email',
                    }}
                    dismissAlertButton={false}
                  />
                  <Input
                    label='Password'
                    className='input'
                    inputProps={{
                      value: password,
                      id: 'password',
                      name: 'password',
                      type: 'password',
                      onChange: (event: any) => {
                        handlePasswordOnChange(event);
                        if (passwordComplexityValidation(event?.target?.value)) {
                          setPasswordError('');
                        }
                      },
                      onBlur: (event: any) => {
                        setPasswordError('');
                        if (event?.target?.value && !passwordComplexityValidation(event?.target?.value)) {
                          setPasswordError(getPasswordRegexMatchMessage(pwdEnforce));
                        }
                      },
                    }}
                    errorMessage={passwordError}
                    dismissAlert={() => setPasswordError('')}
                    dismissAlertButton={false}
                  />
                  <Input
                    label='Repeat Password'
                    className='input'
                    inputProps={{
                      value: repeatPassword,
                      id: 'repeat-password',
                      name: 'repeat-password',
                      type: 'password',
                      onChange: (event: any) => {
                        setRepeatPassword(event.target.value);
                      },
                    }}
                    additionalInfo={{
                      info: pwdEnforce,
                    }}
                  />
                  <div style={{ marginTop: '2rem' }}>
                    <Button
                      buttonType={TypesNamesEnum.ACCENT}
                      height='48'
                      width='100%'
                      onClick={handleOnSubmit}
                      disabled={!password.length || !!passwordError || password !== repeatPassword}
                      isLoading={createAuthAccountMeta.isLoading || loginLoading}
                    >
                      Continue
                    </Button>
                  </div>

                  {TURNSTILE_ENABLED && (
                    <TurnstileWrapper>
                      <Turnstile
                        sitekey={TURNSTILE_SITE_KEY}
                        onVerify={handleTurnstileVerify}
                        onError={handleTurnstileError}
                        onExpire={() => setTurnstileToken('')}
                        theme="light"
                        refreshExpired="auto"
                      />
                    </TurnstileWrapper>
                  )}
                </div>
              )}
            </StyledForm>
          </StyledFormWrapper>
          <Footer />
        </FormContainer>
      </StyleAuthenticationFormContainer>
    </StyledAuthenticationContainer>
  );
};

const TurnstileWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px 0;
`;

const CarrouselContainer = styled.div`
  width: 50vw;
  @media screen and (max-width: 850px) {
    display: none;
  }
`;

const FormContainer = styled.div`
  display: flex;
  align-items: stretch;
  width: 90%;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2vh;
`;

export default SetupCredentials;
