import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ErrorMessage, MarginContainer } from '../Custody';
import CustodyNavigationWidget from '../CustodyNavigationWidget';
import Layout from 'components/templates/Layout';
import useCustodyRedirect from '../extraCustodyHooks/useCustodyRedirect';
import { useNavigate, useParams } from 'react-router-dom';
import useStrategy from './useStrategy';
import styled from 'styled-components';
import useInvest from './useInvest';
import useAssets from 'shared/useAssets';
import Modal from 'components/organisms/Modal';
import { CustodyStyledTable } from '../CustodyStyles';
import { SpecificDefiBalance, useConfirmDefiTransactionRequestMutation, useCreateDefiTransactionRequestMutation } from 'state/store/investApi';
import bigDecimal from 'js-big-decimal';
import Loading from 'components/atoms/Loading';
import { SpinnerSizeEnum } from 'components/atoms/Loading/Loading';
import { ToastType, toast } from 'components/organisms/Toast';
import { Percentage } from 'lib/utils/types';
import getUsDollar from 'shared/dollarFormat';
import moment from 'moment';
import Tooltip from 'components/atoms/Tooltip';
import { Info } from 'assets/icons';
import { palette } from 'lib/theme';
import ReviewButton from 'components/atoms/ReviewButton';
import { ValueInput } from 'components/atoms/ValueInput/ValueInput';
import FormInputBox from 'components/atoms/FormInputBox';

const DivestActionPage = () => {
  const { strategyIdentifier } = useParams();
  const { defiBalances } = useInvest();
  const [ minError, setMinError ] = useState(false)
  const [ maxError, setMaxError ] = useState(false);
  const { loadDivestTransactionInfo, divestInfo, pendingTransactions } = useStrategy(strategyIdentifier ?? '');
  const { getAssetByIdentifier, getPriceFormattedI, assetsLoading } = useAssets();

  useEffect(() => {
      if (strategyIdentifier) {
          loadDivestTransactionInfo();
      }
  }, [strategyIdentifier, loadDivestTransactionInfo]);
  
  const getCurrentInvestment = () => {
    const data = defiBalances.data ?? [];
    const balancesFromThisStrategy = data.filter((d) => d.identifier === strategyIdentifier);
    const balancesWithAmount = balancesFromThisStrategy.filter((d) => +(d.currentBalance?.amount ?? '0'));
    const balances = balancesWithAmount.filter((d) => +(d.yield?.apr ?? '0'));
    const currentInvestment = balances?.length ? balances[0] : undefined;
    return currentInvestment;
  };

  const currentInvestment = getCurrentInvestment();

  const { strategyDetails, strategyBalance } = useStrategy(strategyIdentifier ?? '');

  const getCurrentInvestedValues = () => {
    const data = strategyBalance.data ?? [];
    const pendingData = data.filter((d) => !d.pending);
    const totalInvestedValues = pendingData.reduce(sumDefi, new bigDecimal(0));
    return totalInvestedValues;
  };

  const sumDefi = (total: bigDecimal, balance: SpecificDefiBalance) => {
    return total.add(new bigDecimal(balance.balance?.amount ?? '0'));
  };

  const { getDollarPrice } = useAssets();
  const currentInvestedValues = getCurrentInvestedValues();
  const asset = getAssetByIdentifier(divestInfo.data?.availableAmountInAsset?.asset);

  const pendingDivestTransactions = pendingTransactions.data?.filter((t) => t.actionName === 'Divest' && t.amount.asset === asset?.identifier);
  const pendingDivestAmount = pendingDivestTransactions?.reduce((sum, t) => sum.add(new bigDecimal(t.amount.amount)), new bigDecimal(0));
  const pendingDivestDollarAmount = getDollarPrice(asset?.identifier, pendingDivestAmount?.getValue());
  const minimumDivestDollarAmount = getDollarPrice(divestInfo.data?.minimumTransactionAmount?.asset, divestInfo.data?.minimumTransactionAmount?.amount);

  const [investmentAmount, setInvestmentAmount] = useState('0');

  const [showModal, setShowModal] = useState(false);
  const [transactionPublicUID, setTransactionPublicUID] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const dollarPrice = getDollarPrice(asset?.identifier, investmentAmount.toString());
  const dollarPriceFee = divestInfo.data?.totalEstimatedFeeInConvenience?.amount;
  const [createDefiTransaction, createMeta] = useCreateDefiTransactionRequestMutation();
  const [confirmDefiTransaction, confirmMeta] = useConfirmDefiTransactionRequestMutation();

  let canvas: HTMLCanvasElement;
  const getTextWidth = (text: string, font: any) => {
    canvas = canvas ? canvas : document.createElement('canvas');
    var context = canvas.getContext('2d');
    context!.font = font;
    var metrics = context!.measureText(text);
    return Math.max(metrics.width, 10);
  };

  useEffect(() => {
    setIsLoading(false)
  }, [transactionPublicUID])

  const handleReview = async () => {
    try {
      setIsLoading(true);
      setShowModal(true)
      const transaction = await createDefiTransaction({
        amount: investmentAmount,
        currency: asset?.identifier ?? '',
        transactionType: 'WITHDRAWAL',
        strategyIdentifier: strategyIdentifier ?? '',
      }).unwrap();
      setTransactionPublicUID(transaction.publicUID)
    } catch (e) {
      setIsLoading(false);
      setTransactionPublicUID(null);
      throw e;
    }
  }

  const submit = async () => {
    try {
      if(transactionPublicUID) {
        await confirmDefiTransaction(transactionPublicUID).unwrap();
        toast.show({
          type: ToastType.Success,
          title: 'Your request was successful',
          content: '',
        });
        navigate('/custody');
      }
    } catch (e) {
      toast.show({
        type: ToastType.Fail,
        title: 'There was an error with your request',
        content: 'If the problem persists, please contact our support team!',
      });
    }
  };

  const navigate = useNavigate();
  const maxAmount = useMemo(() => 
    new bigDecimal(divestInfo.data?.availableAmountInAsset?.amount ?? '0'),
    [divestInfo.data]);
  const setMax = () => {
    if(maxAmount.compareTo(new bigDecimal(0)) > 0) {
      setInvestmentAmount(maxAmount.getValue());
    }
  };
  const shouldRenderMaxButton = +(divestInfo.data?.availableAmountInAsset?.amount ?? '0') > 0;
  useCustodyRedirect();

  const [spanWidth, setSpanWidth] = useState(0)
  const valueWidth = getTextWidth(investmentAmount.toString(), '13.3px "IBM Plex Sans"');
  const spanDollarPrice = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    if (!spanDollarPrice.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      if(spanDollarPrice.current?.offsetWidth !== spanWidth) {
        setSpanWidth(spanDollarPrice.current?.offsetWidth ?? 0); 
      }
    });
    resizeObserver.observe(spanDollarPrice.current);
    
    return () => {
      resizeObserver.disconnect();
    }
  }, [spanWidth])

  const minimumAmount = useMemo(() => {
    const minimumDueToFee = new bigDecimal(divestInfo.data?.minimumTransactionAmount?.amount ?? '0');
    return minimumDueToFee
  }, [divestInfo.data]);

  
  useEffect(() => {
    setMinError(investmentAmount !== '0' && new bigDecimal(investmentAmount).compareTo(minimumAmount) < 0);
    setMaxError(new bigDecimal(investmentAmount).compareTo(maxAmount) > 0);
}, [investmentAmount, minimumAmount, maxAmount])

  const renderEstimatedFee = () => (
    <p>
      Estimated network fee is{' '}
      <StrongText>
        {divestInfo.data?.totalEstimatedFeeAmounts.map((fee) => getPriceFormattedI(fee.asset, fee.amount)).join(', ')}
      </StrongText>
      {' '}(${dollarPriceFee})
    </p>
  )

  return (
    <Layout>
      <MarginContainer>
        <CustodyNavigationWidget>
          {divestInfo.isLoading || defiBalances.isLoading || strategyDetails.isLoading || assetsLoading ? (
            <>
              <Loading size={SpinnerSizeEnum.LARGE} showText={false} />
            </>
          ) : (
            <>
              <Header>
                <PageTitle>
                  Divest from {strategyDetails.data?.name}{' '}
                  {currentInvestment ? <>with Current APR of {new Percentage(currentInvestment.yield.apr).print()}</> : <></>}
                </PageTitle>
              </Header>
              <Content>
                <FormInformation>
                  <ForMoreInformation>
                    For more information about this investment strategy, please
                    <CustodyClickableText onClick={() => navigate(`/custody/invest/details/${strategyIdentifier}`)}> click here</CustodyClickableText>
                  </ForMoreInformation>
                  {divestInfo.data?.nextInvestmentCutoff && (
                    <>
                      <NextEffectiveDateBox>
                        <p>You can cancel your divestment request till <StrongText>{moment.utc(divestInfo.data?.nextInvestmentCutoff).format('MMM Do')}</StrongText></p>
                        <p>You will continue earning yield till <StrongText>{moment.utc(divestInfo.data?.lastEffectiveDate).format('MMM Do')}</StrongText></p>
                        <p>
                          You will receive assets between
                          <span>
                            <StrongText> {moment.utc(divestInfo.data?.firstExpectedReturnDate).format('MMM Do')}</StrongText> and
                            <StrongText> {moment.utc(divestInfo.data?.lastExpectedReturnDate).format('MMM Do')}</StrongText>
                          </span>
                        </p>
                      </NextEffectiveDateBox>
                    </>
                  )}
                  {!!divestInfo.data?.estimatedMaxTransactionFeeBasisPoints && (
                    <EstimatedFeeBpsBox>
                      Estimated transaction fee {divestInfo.data?.estimatedMinTransactionFeeBasisPoints} bps -{' '}
                      {divestInfo.data?.estimatedMaxTransactionFeeBasisPoints} bps
                      <Tooltip text={`This is estimated based on what users paid on average in the last ${divestInfo.data.estimatedTransactionFeeIntervals} months.`}>
                        <span>
                          {' '}
                          <Info color='#fffc' size={12} />
                        </span>
                      </Tooltip>
                    </EstimatedFeeBpsBox>
                  )}
                  <p>
                    Current invested amount is <StrongText>{getPriceFormattedI(asset?.identifier, divestInfo.data?.totalAmountInAsset.amount)}</StrongText> 
                    <span>{' '}({getUsDollar(currentInvestedValues.getValue())})</span>
                  </p>
                  
                  <p>
                    Minimum divest amount is <StrongText>{getPriceFormattedI(divestInfo.data?.minimumTransactionAmount?.asset, divestInfo.data?.minimumTransactionAmount?.amount)}</StrongText>
                      <span>{' '}{minimumDivestDollarAmount !== '-' && <>({minimumDivestDollarAmount})</>}</span>
                  </p>
                  
                  {pendingDivestAmount && +pendingDivestAmount.getValue() !== 0 && (<>
                    <p>Please note, you have pending withdrawals in net amount of 
                      <StrongText> {getPriceFormattedI(asset?.identifier, pendingDivestAmount.getValue())}{' '}</StrongText>
                      <span>{pendingDivestDollarAmount !== '-' && <>{' '}({pendingDivestDollarAmount})</>}</span> already in process</p> 
                  </>)}
                  {divestInfo.data?.availableAmountInAsset.amount !== divestInfo.data?.totalAmountInAsset.amount ? (
                    <>
                      <p>
                        Available amount for divest of <strong>{getPriceFormattedI(asset?.identifier, divestInfo.data?.availableAmountInAsset?.amount)}</strong>
                      </p>
                    </>
                  ) : (
                    <></>
                  )}
                  <FormInputBox>
                    <Tooltip text="The actual amount you receive may be lower than the requested amount because of estimated transaction fee, network fee, and management fee.">
                      <p>Choose amount to divest <Info color='#fffc' size={12} /></p> 
                    </Tooltip>
                    <ValueInput 
                      assetName={asset?.name}
                      changeInvestmentAmount={setInvestmentAmount}
                      dollarPrice={dollarPrice}
                      inputAmountSize={getTextWidth(investmentAmount.toString(), '13.3px "IBM Plex Sans"')}
                      inputAreaSize={spanWidth + valueWidth}
                      investmentAmount={investmentAmount}
                      renderMaxButton={shouldRenderMaxButton}
                      setMax={setMax}
                      spanDollarPrice={spanDollarPrice}
                      error={minError || maxError}
                      showTooltip
                      tooltipText='The max option will withdraw all of your assets invested in the strategy in two steps. First, your invested asset will be withdrawn. Second, the yield generated in the current period will be withdrawn in the next withdrawal period. This amount will not accrue yield while it is waiting to be withdrawn. If this withdrawal amount is equal to or less than the network fee to fulfill the withdrawal, the withdrawal amount will be zero. This applies to both first and second withdrawal.'
                    />
                  </FormInputBox>
                </FormInformation>
                <RightAlignedBox>
                  <ReviewButton
                    handleOnClick={handleReview}
                    isDisabled={minError || maxError || investmentAmount === '0' || isLoading}
                    text='Review'
                  />
                </RightAlignedBox>
                {minError && <ErrorMessage>Your divest amount has to be equal or greater than the minimum invest value of {getPriceFormattedI(asset?.identifier, minimumAmount.getValue())}</ErrorMessage>}
                {maxError && <ErrorMessage>Exceeds maximum amount of {getPriceFormattedI(asset?.identifier, maxAmount.getValue())}</ErrorMessage>}
              </Content>
            </>
          )}
        </CustodyNavigationWidget>
        <Modal visible={showModal} onClose={() => setShowModal(false)} header='Confirmation Page'>
          <Modal.Body>
            <ModalContent>
              <p>
                Divest{' '}
                <StrongText>
                  {investmentAmount} {asset?.name}
                </StrongText>{' '}
                {dollarPrice !== '-' && <>({dollarPrice})</>} from
                <CustodyClickableText onClick={() => navigate(`/custody/invest/details/${strategyIdentifier}`)}>
                  {' '}
                  {strategyDetails.data?.name}
                </CustodyClickableText>{' '}
                {currentInvestment && <>with Current APR of {new Percentage(currentInvestment.yield.apr).print()}</>}
              </p>
              <p>
                {renderEstimatedFee()}
              </p>
              {divestInfo.data?.estimatedMinTransactionFeeBasisPoints !== undefined &&
                divestInfo.data?.estimatedMaxTransactionFeeBasisPoints !== undefined && (
                  <p>
                    {divestInfo.data?.estimatedMinTransactionFeeBasisPoints ===
                    divestInfo.data?.estimatedMaxTransactionFeeBasisPoints
                      ?  <span>Estimated transaction fee will be <StrongText>{divestInfo.data.estimatedMinTransactionFeeBasisPoints} bps</StrongText></span>
                      :  <span>Estimated transaction fee will be <StrongText>{divestInfo.data.estimatedMinTransactionFeeBasisPoints} - {divestInfo.data.estimatedMaxTransactionFeeBasisPoints} bps</StrongText></span>
                    }
                    <Tooltip text={`This is estimated based on what users paid on average in the last ${divestInfo.data?.estimatedTransactionFeeIntervals} months. Network fee to send the funds into the strategy will be additional`}><span> <Info color='#fffc' size={12} /></span></Tooltip>
                  </p>
                )}
                {divestInfo.data?.firstExpectedReturnDate && divestInfo.data?.lastExpectedReturnDate && (
                  <p>
                    You will receive your assets between <StrongText>{moment.utc(divestInfo.data.firstExpectedReturnDate).format('MMM Do')}</StrongText> and <StrongText>{moment.utc(divestInfo.data.lastExpectedReturnDate).format('MMM Do')}</StrongText>
                  </p>
                )}
            </ModalContent>
            {!!divestInfo.data?.stepDetails?.length && (
              <AutomationStepsBox>
                <h4>Automation Steps</h4>
                <CustodyStyledTable>
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Expected Fees</th>
                      <th>Fees in USD</th>
                    </tr>
                  </thead>
                  <tbody>
                    {divestInfo.data?.stepDetails?.map((step) => (
                      <tr>
                        <td>{step.description}</td>
                        <td>{step.estimatedFeeAmount ? getPriceFormattedI(step.estimatedFeeAmount?.asset, step.estimatedFeeAmount?.amount) : '-'}</td>
                        <td>
                          {step.estimatedConvenienceFeeAmount
                            ? getPriceFormattedI(step.estimatedConvenienceFeeAmount?.asset, step.estimatedConvenienceFeeAmount?.amount)
                            : '-'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </CustodyStyledTable>
              </AutomationStepsBox>
            )}
          </Modal.Body>
          <Modal.Footer>
            <RightAlignedBox>
              <ReviewButton
                handleOnClick={submit}
                isDisabled={!transactionPublicUID || createMeta.isLoading || confirmMeta.isLoading}
                isLoading={isLoading}
                text='Submit'
              />
            </RightAlignedBox>
          </Modal.Footer>
        </Modal>
      </MarginContainer>
    </Layout>
  );
};


const ForMoreInformation = styled.p`
  margin-bottom: 8px;
`;

const FormInformation = styled.div`
  display: flex;
  flex-direction: column;
`;
const NextEffectiveDateBox = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
`;
const StrongText = styled.strong`
  font-weight: 500;
`
const AutomationStepsBox = styled.div``;
const ModalContent = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  gap: 12px;
  flex-direction: column;
  font-size: 0.9rem;
`;
const RightAlignedBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CustodyClickableText = styled.span`
  cursor: pointer;
  color: #a58bf2;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const EstimatedFeeBpsBox = styled.div`
  margin-bottom: 8px;
`;

const Content = styled.div`
  display: flex;
  gap: 30px;
  flex-direction: column;
`;


const PageTitle = styled.h2`
  font-weight: 600;
  color: ${palette.white.main};
  font-size: 18px;
  line-height: 130%;
  letter-spacing: -0.5px;
  margin-bottom: 30px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export default DivestActionPage;
