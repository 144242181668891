import React, { useEffect, useState } from 'react';
import alternativeGridSystem from 'lib/theme/alternativeGridSystem';
import { useParams } from 'react-router-dom';
import Layout from 'components/templates/Layout';
import { MarginContainer } from '../Custody';
import CustodyNavigationWidget from '../CustodyNavigationWidget';
import moment from 'moment';
import styled from 'styled-components';
import useCustodyStatement from './useCustodyStatement';
import CustodyStatementTokenSummary from './CustodyStatementTokenSummary';
import CustodyStatementInvestmentSummary from './CustodyStatementInvestmentSummary';
import CustodyStatementTransaction from './CustodyStatementTransaction';
import CustodyStatementGainLossDetails from './CustodyStatementGainLossDetails';
import CustodyStatementCostBasisWithdrawals from './CustodyStatementCostBasisWithdrawals';
import PrintIcon from 'assets/icons/PrintIcon';
import getUsDollar from 'shared/dollarFormat';
import bigDecimal from 'js-big-decimal';
import CustodyStatementInvestmentExpenseSummary from './CustodyStatementInvestmentExpenseSummary'
import { CustodyMainText } from '../CustodyStyles';
import Loading from 'components/atoms/Loading';
import { SpinnerSizeEnum } from '@types/enums/Loading.enum';
import { palette } from 'lib/theme';
import CustodyStatementDefiLoanSummary from './CustodyStatementDefiLoanSummary';
import { useGetStatementDefiLoanCollateralQuery, useGetStatementDefiLoanPrincipalQuery } from 'state/store/statementsApi';
import SharedLoadingState from './SharedLoadingState';
import CustodyInvestmentFootnotes from './CustodyStatementFootNotes';
import Row from 'components/atoms/Row/Row';

const CustodyStatement: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { month, year } = useParams();
  const yearMonth = {year, month: month?.padStart(2, '0')}
  const date = new Date(+year!, +month! - 1);
  const lastDay = moment(date).endOf('month');
  const firstDay = moment(date).startOf('month');
  const { smaAccountNumber, totalStatement, totalBalancePeriod: endingCustodyBalance, totalInvestmentBalance: endingInvestmentBalance, balance, beginningInvestmentBalance } = useCustodyStatement({ year, month });

  const beginningCustodyBalance = balance.data?.reduce((acc, item) => acc.add(new bigDecimal(item.startingBalance.amount ?? 0)), new bigDecimal(0));
  
  const collateralBalance = useGetStatementDefiLoanCollateralQuery(yearMonth);
  const principalBalance = useGetStatementDefiLoanPrincipalQuery(yearMonth);

  const beginningCollateralValue = collateralBalance.data?.reduce((acc, item) => acc.add(new bigDecimal(item.startingValue.amount ?? 0)), new bigDecimal(0)).getValue();
  const endingCollateralValue = collateralBalance.data?.reduce((acc, item) => acc.add(new bigDecimal(item.endingValue.amount ?? 0)), new bigDecimal(0)).getValue();
  const beginningBorrowValue = principalBalance.data?.reduce((acc, item) => acc.add(new bigDecimal(item.startingValue.amount ?? 0)), new bigDecimal(0)).getValue();
  const endingBorrowValue = principalBalance.data?.reduce((acc, item) => acc.add(new bigDecimal(item.endingValue.amount ?? 0)), new bigDecimal(0)).getValue();

  const beginningBalance = 
    new bigDecimal(beginningCustodyBalance?.getValue())
      .add(new bigDecimal(beginningInvestmentBalance))
      .add(new bigDecimal(beginningCollateralValue))
      .getValue();
  const endingBalance = 
    new bigDecimal(endingCustodyBalance)
      .add(new bigDecimal(endingInvestmentBalance))
      .add(new bigDecimal(endingCollateralValue))
      .getValue();

  useEffect(() => {
    setIsLoading(
      balance.isLoading || 
      totalStatement.isLoading   
    )
  }, [balance.isLoading, totalStatement.isLoading])

  const print = () => {
    window.print();
    return false;
  };

  return (
    <Layout customGrid={alternativeGridSystem}>
      <MarginContainer id='pdf-area'>
        <CustodyNavigationWidget>
        {isLoading ? (
            <SharedLoadingState />
          ) : (
            <>
          <TitlePage>
            Statement{' '}
            <PrintButton disabled={isLoading} onClick={print}>
              {isLoading ?
                  <Loading
                    showText={false}
                    size={SpinnerSizeEnum.INPUT}
                    showRoundSpinner
                    spinnerColor={palette.white.main}
                  /> :
                <PrintIcon />
              }
            </PrintButton>
          </TitlePage>
          <HorizontalFlexContainer style={{paddingBottom: 20}}>
            <BoxValues>
              <PairTitle>SMA Account Number</PairTitle>
              <PairValue>{smaAccountNumber}</PairValue>
            </BoxValues>
            <div></div>
            <div></div>
            <BoxValues>
              <PairTitle>Period</PairTitle>
              <PairValue>
                {firstDay.format('MMMM Do, YYYY')} - {lastDay.format('MMMM Do, YYYY')}
              </PairValue>
            </BoxValues>
          </HorizontalFlexContainer>
          <TableRow>
            <BigTableWithBorder >
              <thead>
                <tr>
                  <Th></Th>
                  <Th></Th>
                  <Th><PairTitle>Custody</PairTitle></Th>
                  <Th><PairTitle>Investment</PairTitle></Th>
                  <Th><PairTitle>Collateral</PairTitle></Th>
                </tr>
              </thead>
              <tbody>
                <TRow>
                  <Td><PairTitle>Total Beginning Balance</PairTitle></Td>
                  <Td><PairValue>{getUsDollar(beginningBalance)}</PairValue></Td>
                  <Td><PairValue>{getUsDollar(beginningCustodyBalance?.getValue())}</PairValue></Td>
                  <Td><PairValue>{getUsDollar(beginningInvestmentBalance)}</PairValue></Td>
                  <Td><PairValue>{getUsDollar(beginningCollateralValue)}</PairValue></Td>
                </TRow>
                <TRow>
                  <Td><PairTitle>Total Closing Balance</PairTitle></Td>
                  <Td><PairValue>{getUsDollar(endingBalance)}</PairValue></Td>
                  <Td><PairValue>{getUsDollar(endingCustodyBalance)}</PairValue></Td>
                  <Td><PairValue>{getUsDollar(endingInvestmentBalance)}</PairValue></Td>
                  <Td><PairValue>{getUsDollar(endingCollateralValue)}</PairValue></Td>
                </TRow>
              </tbody>
            </BigTableWithBorder>
            <SmallTableWithBorder>
              <thead>
                <tr>
                  <Th></Th>
                  <Th><PairTitle>Borrow</PairTitle></Th>
                </tr>
              </thead>
              <tbody>
                <TRow>
                  <Td><PairTitle>Beginning Balance</PairTitle></Td>
                  <Td><PairValue>{getUsDollar(beginningBorrowValue)}</PairValue></Td>
                </TRow>
                <TRow>
                  <Td><PairTitle>Closing Balance</PairTitle></Td>
                  <Td><PairValue>{getUsDollar(endingBorrowValue)}</PairValue></Td>
                </TRow>
              </tbody>
            </SmallTableWithBorder>
          </TableRow>
          <TableRow>
            <BigTable>
              <thead>
                <tr>
                  <Th></Th>
                  <Th></Th>
                  <Th><PairTitle>Realized Gain/Loss YTD</PairTitle></Th>
                  <Th><PairTitle>Income YTD</PairTitle></Th>
                  <Th><PairTitle>Income MTD</PairTitle></Th>
                </tr>
              </thead>
              <tbody>
                <TRow>
                  <Td></Td>
                  <Td></Td>
                  <Td><PairValue>{getUsDollar(totalStatement.data?.realizedAmountYtd.amount)}</PairValue></Td>
                  <Td><PairValue>{getUsDollar(totalStatement.data?.incomeAmountYtd.amount)}</PairValue></Td>
                  <Td><PairValue>{getUsDollar(totalStatement.data?.incomeAmount.amount)}</PairValue></Td>
                </TRow>
              </tbody>
            </BigTable>
            <SmallTable>
              <thead>
                <tr>
                  <Th><PairTitle>Interest YTD</PairTitle></Th>
                  <Th><PairTitle>Interest MTD</PairTitle></Th>
                </tr>
              </thead>
              <tbody>
                <TRow2>
                  <Td><PairValue>{getUsDollar(totalStatement.data?.interestAmountYtd.amount)}</PairValue></Td>
                  <Td><PairValue>{getUsDollar(totalStatement.data?.interestAmount.amount)}</PairValue></Td>
                </TRow2>
              </tbody>
            </SmallTable>
          </TableRow>
          <CustodyStatementTokenSummary />
          <CustodyStatementInvestmentSummary />
          <CustodyStatementDefiLoanSummary />
          <CustodyStatementInvestmentExpenseSummary />
          <CustodyStatementTransaction />
          <CustodyStatementGainLossDetails />
          <CustodyStatementCostBasisWithdrawals />
          <br></br>
          <br></br>
          <CustodyInvestmentFootnotes/>
          </>
          )}
        </CustodyNavigationWidget>
      </MarginContainer>
    </Layout>
  );
};

const BoxValues = styled.div``;

const PrintButton = styled.button`
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6f2acd99;
  padding: 6px;
  border-radius: 50%;
  transition: all 1s;
  border: unset;
  &:hover {
    background-color: #6f2acd;
  }
`;


const HorizontalFlexContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

const TitlePage = styled.h2`
  font-weight: 600;
  color: #ffffff;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  line-height: 130%;
  letter-spacing: -0.5px;
  margin-bottom: 30px;
  @media print {
    color: black !important;
    font-weight: bold;
    td & {
      font-size: 12px
    }
    
  }
`;

const PairTitle = styled(CustodyMainText)`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.045em;
  text-transform: uppercase;
`;

const PairValue = styled(CustodyMainText)`
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -0.48px;
  @media print {
    td & {
      font-size: 12px;
      color: black;
    }
  }
`;

const Td = styled.td`
  padding: 10px;
`

const Th = styled.th`
  padding: 10px;
  text-align: right;
`

const TRow = styled.tr`
  td {
    text-align: right;
    &:first-child {
      text-align: left;
    }
  }
  
`

const TRow2 = styled.tr`
  td {
    text-align: right;
  }
`

const TableRow = styled(Row)`
  justify-content: space-between; 
  padding-bottom: 20px;
`
const BigTable = styled.table`
  table-layout: fixed;
  width: 65%;
`

const BigTableWithBorder = styled(BigTable)`
  border: 1px solid white;
  border-radius: 4px;
  @media print {
    border: 1px solid black;
  }
`

const SmallTable = styled.table`
  table-layout: fixed;
  width: 25%;
`

const SmallTableWithBorder = styled(SmallTable)`
  border: 1px solid white;
  border-radius: 4px;
  @media print {
    border: 1px solid black;
  }
`


export default CustodyStatement;
