import { Copy, Download, Info } from 'assets/icons';
import Image from 'components/atoms/Image';
import Loading from 'components/atoms/Loading';
import { SpinnerSizeEnum } from 'components/atoms/Loading/Loading';
import 'react-datepicker/dist/react-datepicker.css';
import WhiteSpinner from 'components/atoms/Spinner/WhiteSpinner';
import { toast, ToastType } from 'components/organisms/Toast';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import usePermissions from 'shared/usePermissions';
import { AssetDto } from 'state/store/api';
import { CustodyBalance, useGetCustodyBalanceCSVMutation } from 'state/store/custodyApi';
import styled from 'styled-components';
import { CustodyMainText, CustodyStyledTable, CustodySubText } from './CustodyStyles';
import useCustodyWithdraw from './extraCustodyHooks/useCustodyWithdraw';
import useDeposits from './extraCustodyHooks/useDeposits';
import useCustody from './useCustody';
import Modal from 'components/organisms/Modal';
import Button from 'components/atoms/Button';
import { SizeNamesEnum, TypesNamesEnum } from 'enums/Button.enum';
import { Percentage } from 'lib/utils/types';
import useInvest from './invest/useInvest'
import getUsDollar from 'shared/dollarFormat'
import Tooltip from 'components/atoms/Tooltip'
import TokenQuantity from 'components/atoms/TokenQuantity/TokenQuantity'
import { isNegative } from 'utils/isNegative';
import bigDecimal from 'js-big-decimal';
import { allowedWithdraw } from 'utils/allowedWithdraw';

const CustodyTokenSummary = () => {

  const { balance, getAssetByIdentifier, isLoadingBalance: loadingBalance } = useCustody();
  const [openModal, setOpenModal] = useState(false);;
  const [balanceApi, balanceApiMeta] = useGetCustodyBalanceCSVMutation()
  

  const getCSV = async () => {
    
    await balanceApi().unwrap()
    toast.show({
      type: ToastType.Success,
      title: "File download successfully",
      content: "Check your download folder",
    });
    setOpenModal(false)
  };

  return (
    <div>
      <TokenSummaryTitle>
        <TokenSummaryTitle>Custody Details</TokenSummaryTitle>
        <Modal visible={openModal} onClose={() => setOpenModal(false)} header='Download Balance Details'>
          <ModalBody>
            <ModalDescription>Download details of assets under custody as of today.</ModalDescription>
            <ButtonRow>
              <Button buttonType={TypesNamesEnum.SECONDAY} size={SizeNamesEnum.EXTRA_LARGE_PLUS} label='Cancel' onClick={() => setOpenModal(false)} />
              <Button isLoading={balanceApiMeta.isLoading} buttonType={TypesNamesEnum.ACCENT} size={SizeNamesEnum.EXTRA_LARGE_PLUS} label='Download' onClick={getCSV} />
            </ButtonRow>
          </ModalBody>
        </Modal>
        {!loadingBalance && (
          <DownloadSpreadSheetSpan onClick={() => setOpenModal(true)}>
            {balanceApiMeta.isLoading ? (
              <>
                <WhiteSpinner />
              </>
            ) : (
              <>
                <Download color='white' size={15} />
                <span>Download</span>
              </>
            )}
          </DownloadSpreadSheetSpan>
        )}
      </TokenSummaryTitle>
      {loadingBalance && <Loading size={SpinnerSizeEnum.LARGE} showText={false} />}
      {!loadingBalance && balance.length === 0 && <CustodyMainText>You don’t have any custodied assets. Please fund your account by depositing USD or crypto.</CustodyMainText>}
      {!loadingBalance && !!balance.length && (
        <CustodyStyledTable>
          <thead>
            <tr>
              <th>Asset</th>
              <th>Address</th>
              <th>Market Value</th>
              <th>Quantity</th>
              <th>Price</th>
              <Tooltip placement='top' text="Any unrealized gain/ loss prior to Abra deposit is not accounted for">
                <UnrealizedGainLossTitle>Unrealized Gain / Loss <Info color='#fffa' size={15}/></UnrealizedGainLossTitle>
              </Tooltip>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {balance.map((record, index) => (
              <TokenSummaryRow key={`${record.balanceCurrency}-${record.network}`} record={record} asset={getAssetByIdentifier(record.balanceCurrency)} />
            ))}
          </tbody>
        </CustodyStyledTable>
      )}
    </div>
  );
};

interface TokenSummaryRowProps {
  asset?: AssetDto;
  record: CustodyBalance;
}

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2vh;
  padding: 2vh 2vw;
`
const ModalDescription = styled.p``;

const TokenSummaryRow: React.FC<TokenSummaryRowProps> = ({ asset, record }) => {
  const { getPrice } = useCustody();
  const { assetsForDeposit } = useDeposits();
  const { canInvestAsset } = useInvest()

  const { availableAssets } = useCustodyWithdraw();
  const navigate = useNavigate();
  const availableForDeposit = assetsForDeposit.find((a) => asset?.identifier === a?.identifier);
  const availableForWithdraw = availableAssets.find((a) => asset?.identifier === a);
  const { canDepositCustody, canWithdrawCustody, canInvest, canWithdrawFiatCustody } = usePermissions();
  const copyAddress = () => {
    try {
      navigator.clipboard.writeText(record.cryptoAddress);
      toast.show({
        title: 'Address copied',
        content: 'The value was copied to the clipboard',
        duration: 5000,
        type: ToastType.Info,
      });
    } catch (e) {}
  };
  const displayUnrealizedGain = () => {
    const unrealizedAmount = record.unrealizedAmount ? getUsDollar(getPrice(record.unrealizedAmount?.asset, record.unrealizedAmount?.amount)) : '-';
    const unrealizedPercentage = new Percentage(record.unrealizedPercentage)?.print();
    return `${unrealizedAmount} (${unrealizedPercentage})`;
  }

  return (
    <tr>
      <Td>
        <TokenImageBox>
          {asset && (
            <>
              <ImageBox>
                <Image width='100%' height='100%' src={asset?.icon} />
              </ImageBox>
              <TextGroup>
                <CustodyMainText>{asset?.name}</CustodyMainText>
                <CustodySubText>{record.network}</CustodySubText>
              </TextGroup>
            </>
          )}
          {!asset && (
            <>
              <TextGroup>
                <CustodyMainText>{record.balanceCurrency}</CustodyMainText>
                <CustodySubText>{record?.network}</CustodySubText>
              </TextGroup>
            </>
          )}
        </TokenImageBox>
      </Td>
      <Td>
        {record.cryptoAddress ? (
          <>
            <CryptoAddressBox title={record.cryptoAddress} onClick={copyAddress}>
              <CopyBox>
                <Copy />
              </CopyBox>
              <CryptoAddress>{record.cryptoAddress}</CryptoAddress>
            </CryptoAddressBox>
          </>
        ) : (
          '-'
        )}
      </Td>
      <Td>
        {
          record.currentValue && 
            <CustodyMainText 
              isNegative={isNegative(record.currentValue.amount)}
            >
                {getUsDollar(record.currentValue.amount)}
            </CustodyMainText>
        }
      </Td>
      <Td>
        <TextGroup>
          <CustodyMainText isNegative={isNegative(record.currentBalance?.amount)}>
            <TokenQuantity asset={record.currentBalance?.asset} quantity={record.currentBalance?.amount} />
          </CustodyMainText>
          
        </TextGroup>
      </Td>
      <Td>{record.currentPrice?.rate ? getUsDollar(record.currentPrice?.rate) : '-'}</Td>
      <Td>
        <TextGroup>
          <CustodyMainText isNegative={isNegative(record.unrealizedAmount?.amount)}>
            {displayUnrealizedGain()}
          </CustodyMainText>
        </TextGroup>
      </Td>
      <Td>
          {canInvest && 
            canInvestAsset(asset?.identifier ?? '') ? <>
              <ActionButton position={1} onClick={() => navigate(`/custody/invest/asset/${asset?.identifier}`)}>
                <span>Invest</span>
              </ActionButton>
            </> : <InvisibleButton></InvisibleButton>}
      </Td>    
      <Td>
      {canDepositCustody ? (
            <>
              {availableForDeposit ? (
                <ActionButton position={2}>
                  <span onClick={() => navigate(`/custody/deposit/${asset?.identifier}`)}>Deposit</span>
                </ActionButton>
              ) : (
                <InvisibleButton></InvisibleButton>
              )}
            </>
          ) : (
            <InvisibleButton></InvisibleButton>
          )}
      </Td>
      <Td>
      {canWithdrawCustody ? (
            <>
              {availableForWithdraw ? (
                <ActionButton position={3}>
                  <span onClick={() => navigate(`/custody/withdraw/${asset?.identifier}`)}>Withdraw</span>
                </ActionButton>
              ) : (
                <InvisibleButton></InvisibleButton>
              )}
            </>
          ) : (
            <InvisibleButton></InvisibleButton>
          )}
      </Td>
      <Td>
      {asset?.name && canWithdrawFiatCustody && allowedWithdraw.includes(asset.name) ? (
            <>
              <ActionButton position={4}>
                <span 
                  onClick={() => navigate(`/custody/withdraw-fiat?asset=${asset?.identifier}&network=${record?.networkName}`)}
                >
                  Withdraw USD
                </span>
              </ActionButton>
            </>
          ) : (
            <InvisibleButton></InvisibleButton>
          )}
      </Td>
    </tr>
  );
};

const Td = styled.td`
  min-width: 60px
`

const ButtonRow = styled.div`
  display: flex;
  justify-content: stretch;
  gap: 1vw;
`;

const DownloadSpreadSheetSpan = styled.span`
  background-color: #6f2acd80;
  border-radius: 6px;
  font-size: 80%;
  display: flex;
  align-items: center;
  gap: 1vw;
  padding: 0.6vw 1vw;
  cursor: pointer;
  transition: background-color 0.3s ease-in;
  &:hover {
    background-color: #292749;
  }
`;

const InvisibleButton = styled.div`

`;

const UnrealizedGainLossTitle = styled.th`
  display: flex;
  align-items: center;
  gap: 3px;
  cursor: pointer;
`
interface GridPosition {
  position: number
}
const ActionButton = styled.div<GridPosition>`
  grid-column: ${({position}) => position} / span 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #6f2acd;
  border-radius: 0.5vw;
  text-transform: uppercase;
  color: #fff;
  font-size: 70%;
  font-weight: 700;
  padding: 4px 1vw;
  transition: background-color 0.3s ease-in;
  cursor: pointer;
  &:hover {
    background-color: #292749;
  }
`;
const ButtonsArea = styled.div`
  display: grid;
  width: fit-content;
  grid-template-columns: 1fr 1fr 1fr;
  height: fit-content;
  align-items: center;
  gap: 0.5vw;
  flex-wrap: wrap;
`;
const ImageBox = styled.div`
  max-width: calc(min(4vw, 32px));
  min-width: calc(min(4vw, 32px));
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CopyBox = styled.div`
  width: 20px;
  height: 20px;
`;
const CryptoAddressBox = styled.div`
  cursor: pointer;
  max-width: 8ch;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const CryptoAddress = styled.span`
  text-overflow: ellipsis;
  max-width: 10vw;
  overflow: hidden;
`;
const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const TokenImageBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6vw;
`;
const TokenSummaryTitle = styled.div`
  font-weight: 600;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 18px;
  margin-bottom: 6px;
  letter-spacing: -0.5px;
`;
export default CustodyTokenSummary;
