import { AssetAmountPair } from "interfaces/AssetAmountPair.interface";
import { api } from "./api";
import { AccessTokenWrapper, EmailValidation, OtpStartValidation, OtpValidation, WithdrawRequest } from "./withdrawApi";

export const fiatWithdrawApi = api.injectEndpoints({
    endpoints: (build) => ({
        getWithdrawInfo: build.query<FiatWithdrawInfoResponse, FiatWithdrawInfoRequest>({
            query: (body) => ({
                url: `/custody/fiat/withdraw/info`,
                method: 'POST',
                body
            }),
        }),
        getWithdrawTransactionRequest: build.mutation<FiatWithdrawTransactionResponse, FiatWithdrawTransactionRequest>({
            query: (body) => ({
                url: `/custody/fiat/withdraw/request`,
                method: 'POST',
                body
            }),
        }),
        getWithdrawTransactionConfirm: build.mutation<FiatWithdrawTransactionResult, string>({
            query: (withdrawRequestId) => ({
                url: `/custody/fiat/withdraw/confirm/${withdrawRequestId}`,
                method: 'POST'
            }),
        }),
        withdrawRequestPasswordValidationFiat: build.mutation<AccessTokenWrapper, OtpStartValidation>({
            query: (otpStart) => ({ url: `/custody/fiat/withdraw/otp-start`, method: 'POST', body: otpStart }),
        }),
        withdrawRequestOtpValidation: build.mutation<void, OtpValidation>({
            query: (otpValidate) => ({ url: `/custody/fiat/withdraw/otp-validate/${otpValidate.withdrawRequestId}`, method: 'POST', body: otpValidate.validation }),
        }),
        withdrawRequestEmailValidationFiat: build.mutation<void, EmailValidation>({
            query: (emailValidate) => ({ url: `/custody/fiat/withdraw/email-validate/${emailValidate.withdrawRequestId}`, method: 'POST', body: { code: emailValidate.code } }),
        }),
        sendEmailVerificationFiat: build.mutation<WithdrawRequest, string>({
            query: (withdrawRequestId) => ({ url: `/custody/fiat/withdraw/email-start/${withdrawRequestId}`, method: 'POST'})
        }),
    })
})

export interface BankInfo {
    recipientName: string;
    recipientAddress: string;
    bankRoutingNumber: string;
    bankAccountNumber: string;
    bankName: string;
    bankAddress: string;
    swiftCode: string;
    notes: string;
    bankTransactionPublicUID?: string;
    bankTransactionId?: string;
};

export interface FiatWithdrawTransactionRequest {
    currency: string;
    withdrawalAmount: string;
    network: string;
    bankInfo: BankInfo;
}

export interface FiatWithdrawTransactionResponse {
    id: number;
    externalRequestUid: string;
    status: string;
}

export interface FiatWithdrawInfoRequest {
    currency: string,
    network: string
}

export interface FiatWithdrawInfoResponse {
    availableAmountInAsset: AssetAmountPair;
    availableAmountInFeeAsset: AssetAmountPair;
    estimatedNetworkFee: AssetAmountPair;
    fixedWireTransferFee: AssetAmountPair;
    maxWithdrawalAmount: AssetAmountPair;
    minWithdrawalAmount: AssetAmountPair;
    totalAmountInAsset: AssetAmountPair;
    variableConversionFeeBps: number;
}

export interface FiatWithdrawTransactionResult {
    id: number;
    currency: string;
    amount: string;
    whitelistAddress: string;
    orderId: number;
    transactionId: number;
    transactionIdRollback: number;
    organizationId: number;
    name: string;
    emailApproved: boolean;
    otpApproved: boolean;
    requiresExternalApproval: boolean;
    externalApproved: boolean;
    status: string;
    networkTransactionId: string;
    processingDate: string;
    requestDate: string;
    creationDate: string;
    lastUpdateDate: string;
    withdrawalFee: string;
    externalRequestUid: string;
}

export const {
    useLazyGetWithdrawInfoQuery,
    useGetWithdrawTransactionRequestMutation,
    useGetWithdrawTransactionConfirmMutation,
    useWithdrawRequestEmailValidationFiatMutation,
    useWithdrawRequestOtpValidationMutation,
    useSendEmailVerificationFiatMutation,
    useWithdrawRequestPasswordValidationFiatMutation,
} = fiatWithdrawApi;
  