import Select from 'components/molecules/Select';
import React, { FC } from 'react';
import NetworkCard from './NetworkCard';
import useNetworks from './useNetworks'
import { AssetWhitelistAddressInformation } from './WhitelistedAddresses'
import useCustodyWithdraw from 'components/pages/custody/extraCustodyHooks/useCustodyWithdraw';

interface NetworksProps {
  asset?: AssetWhitelistAddressInformation;
}

const Networks: FC<NetworksProps> = ({ asset }) => {
  const { networks, setNetwork, selectedNetwork, setWithdrawalNetwork, selectedWithdrawalNetwork, loadingNetworks, custodyAssetNetworkInformation } = useNetworks({boost: asset?.isBoost ?? false, custodyWithdraw: asset?.isCustody ?? false, custodyDeposit: false})
  const { selectedAsset } = useCustodyWithdraw();
  return !loadingNetworks ? (
    <>
      {networks.length > 1 && (
        <div>
          <Select
            data={networks.map((network) => ({ id: network.network, label: network.description }))}
            selectedItem={selectedNetwork ? {id: selectedNetwork?.network, label: selectedNetwork?.description} : undefined}
            label='Select the network'
            onSelect={(item) => {
              let network = networks.find((n) => n.network === item.id)!;
              setNetwork(network);
              setWithdrawalNetwork(network);
            }}
          />
          {selectedNetwork && <NetworkCard network={selectedNetwork} info={custodyAssetNetworkInformation}/>}
        </div>
      )}
      {networks.length === 1 && (
        <div>
          <div>Selected Network</div>
          {selectedNetwork && <NetworkCard network={selectedNetwork} info={custodyAssetNetworkInformation}/>}
          {selectedWithdrawalNetwork && <NetworkCard network={selectedWithdrawalNetwork} info={custodyAssetNetworkInformation}/>}
        </div>
      )}
      {networks.length === 0 && selectedAsset && <p>Currently, there are no networks approved for transfering {asset?.asset?.name}, please contact your Account Manager.</p>}
    </>
  ) : (
    <></>
  );
};

export default Networks;
