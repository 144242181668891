import React, { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { ErrorMessage, MarginContainer } from '../Custody';
import CustodyNavigationWidget from '../CustodyNavigationWidget';
import Layout from 'components/templates/Layout';
import useCustodyRedirect from '../extraCustodyHooks/useCustodyRedirect';
import { useNavigate, useParams } from 'react-router-dom';
import useStrategy from './useStrategy';
import styled from 'styled-components';
import useInvest from './useInvest';
import useAssets from 'shared/useAssets';
import Modal from 'components/organisms/Modal';
import { CustodyStyledTable } from '../CustodyStyles';
import { SpecificDefiBalance, useConfirmDefiTransactionRequestMutation, useCreateDefiTransactionRequestMutation } from 'state/store/investApi';
import bigDecimal from 'js-big-decimal';
import Loading from 'components/atoms/Loading';
import { SpinnerSizeEnum } from 'components/atoms/Loading/Loading';
import { ToastType, toast } from 'components/organisms/Toast';
import { Percentage } from 'lib/utils/types';
import getUsDollar from 'shared/dollarFormat';
import Tooltip from 'components/atoms/Tooltip';
import { Info, QuestionCircle } from 'assets/icons'
import ReviewButton from 'components/atoms/ReviewButton';
import { StatusCodes } from 'http-status-codes';
import { ValueInput } from 'components/atoms/ValueInput/ValueInput';
import FormInputBox from 'components/atoms/FormInputBox';
import { palette } from 'lib/theme';
import BalanceExplanationPopup from '../withdrawal/BalanceExplanationPopup';
import { BalanceExplanation } from 'state/store/custodyApi';
import { AssetAmountPair } from 'interfaces/AssetAmountPair.interface';

const InvestStrategySelected = () => {
  const { strategyIdentifier } = useParams();
  const { defiBalances } = useInvest();
  const [ minError, setMinError ] = useState(false);
  const [ maxError, setMaxError ] = useState(false);
  const { loadInvestTransactionsInfo, investInfo } = useStrategy(strategyIdentifier ?? '');
  const { getAssetByIdentifier, getPriceFormattedI, assetsLoading, getDollarPrice } = useAssets();
  const getCurrentInvestment = () => {
    const data = defiBalances.data ?? [];
    const balancesFromThisStrategy = data.filter((d) => d.identifier === strategyIdentifier);
    const balancesWithAmount = balancesFromThisStrategy.filter((d) => +(d.currentBalance?.amount ?? '0'));
    const balances = balancesWithAmount.filter((d) => +(d.yield?.apr ?? '0'));
    const currentInvestment = balances?.length ? balances[0] : undefined;
    return currentInvestment;
  };

  useEffect(() => {
    if (strategyIdentifier) {
      loadInvestTransactionsInfo();
    }
  }, [strategyIdentifier, loadInvestTransactionsInfo]);

  
  const currentInvestment = getCurrentInvestment();

  const { strategyDetails, strategyBalance } = useStrategy(strategyIdentifier ?? '');

  const getCurrentInvestedValues = () => {
    const data = strategyBalance.data ?? [];
    const pendingData = data.filter((d) => !d.pending);
    const totalInvestedValues = pendingData.reduce(sumDefi, new bigDecimal(0));
    return totalInvestedValues;
  };

  const sumDefi = (total: bigDecimal, balance: SpecificDefiBalance) => {
    return total.add(new bigDecimal(balance.balance?.amount ?? '0'));
  };

  const currentInvestedValues = getCurrentInvestedValues();

  const [investmentAmount, setInvestmentAmount] = useState('0');

  const changeInvestmentAmount = (value: string) => {
    const num = value.replace(/[^.0-9]/g, '');
    setInvestmentAmount(num);
  }
  
  const [showModal, setShowModal] = useState(false);
  const [transactionPublicUID, setTransactionPublicUID] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const asset = getAssetByIdentifier(investInfo.data?.availableAmountInAsset?.asset);

  const dollarPrice = getDollarPrice(asset?.identifier, investmentAmount);
  const dollarPriceFee = investInfo.data?.totalEstimatedFeeInConvenience.amount;
  const [createDefiTransaction, createMeta] = useCreateDefiTransactionRequestMutation();
  const [confirmDefiTransaction, confirmMeta] = useConfirmDefiTransactionRequestMutation();

  useEffect(() => {
    setIsLoading(false)
  }, [transactionPublicUID])

  const handleShowModal = async () => {
    try {
      setIsLoading(true);
      setShowModal(true)
      const transaction = await createDefiTransaction({
        amount: investmentAmount,
        currency: asset?.identifier ?? '',
        transactionType: 'DEPOSIT',
        strategyIdentifier: strategyIdentifier ?? '',
      }).unwrap();
      setTransactionPublicUID(transaction.publicUID)
    } catch (e) {
      setIsLoading(false);
      setTransactionPublicUID(null);
    }
  }

  const submit = async () => {
    try {
      if(transactionPublicUID) {
        await confirmDefiTransaction(transactionPublicUID).unwrap();
        toast.show({
          type: ToastType.Success,
          title: 'Your request was successful',
          content: '',
        });
        navigate('/custody');
      }
    } catch (e: any) {
      if(e?.status === StatusCodes.INTERNAL_SERVER_ERROR) {
        toast.show({
          type: ToastType.Fail,
          title: e.data?.description,
          content: e.data?.message,
          duration: 20000,
        });
      } else {
        toast.show({
          title: "An error has occurred: " + e?.status,
          content: "Please try again later or contact your account manager.",
          type: ToastType.Fail,
          duration: 20000,
        });
      }
    }
  };
  let canvas: HTMLCanvasElement;
  const getTextWidth = (text: string, font: any) => {
    canvas = canvas ? canvas : document.createElement('canvas');
    var context = canvas.getContext('2d');
    context!.font = font;
    var metrics = context!.measureText(text);
    return Math.max(metrics.width, 10);
  };

  const navigate = useNavigate();

  useCustodyRedirect();
  const maxAmount = useMemo(() => 
    new bigDecimal(investInfo.data?.availableAmountInAsset?.amount ?? '0'),
    [investInfo.data]);
  const setMax = () => {
    if(maxAmount.compareTo(new bigDecimal(0)) > 0) {
      setInvestmentAmount(maxAmount.getValue());
    }
  }
  const shouldRenderMaxButton = +(investInfo.data?.availableAmountInAsset?.amount ?? '0') > 0
  const accountBalanceInDollars = getDollarPrice(asset?.identifier, investInfo.data?.totalAmountInAsset?.amount)
  const availableBalanceInDollars = getDollarPrice(asset?.identifier, investInfo.data?.availableAmountInAsset?.amount)

  const renderEstimatedFee = () => (
    <p>
      Estimated network fee is{' '}
      <StrongText>
        {investInfo.data?.totalEstimatedFeeAmounts.map((fee) => getPriceFormattedI(fee.asset, fee.amount)).join(', ')}
      </StrongText>
      {' '}(${dollarPriceFee})
    </p>
  )
  const [spanWidth, setSpanWidth] = useState(0)
  const valueWidth = getTextWidth(investmentAmount.toString(), '13.3px "IBM Plex Sans"');
  const spanDollarPrice = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    if (!spanDollarPrice.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      if(spanDollarPrice.current?.offsetWidth !== spanWidth) {
        setSpanWidth(spanDollarPrice.current?.offsetWidth ?? 0); 
      }
    });
    resizeObserver.observe(spanDollarPrice.current);
    
    return () => {
      resizeObserver.disconnect();
    }
  }, [spanWidth])

  const minimumAmount = useMemo(() => {
    const minimumDueToFee = new bigDecimal(investInfo.data?.minimumTransactionAmount?.amount ?? '0');
    return minimumDueToFee
  }, [investInfo.data]);

  useEffect(() => {
      setMinError(investmentAmount !== '0' && new bigDecimal(investmentAmount).compareTo(minimumAmount) < 0);
      setMaxError(new bigDecimal(investmentAmount).compareTo(maxAmount) > 0);
  }, [investmentAmount, minimumAmount, maxAmount])

  const [showExplanation, setShowExplanation] = useState(false);

  const renderEstimatedTransactionFee = () => {
    const valueExist = investInfo.data?.estimatedMaxTransactionFeeBasisPoints !== undefined
      && investInfo.data?.estimatedMinTransactionFeeBasisPoints !== undefined;
    const isSameValue = investInfo.data?.estimatedMaxTransactionFeeBasisPoints === investInfo.data?.estimatedMinTransactionFeeBasisPoints;
    return valueExist && <EstimatedFeeBpsBox>
      Estimated transaction fee of {isSameValue ? <StrongText>{investInfo.data?.estimatedMinTransactionFeeBasisPoints} bps</StrongText> :
        <StrongText>{investInfo.data?.estimatedMinTransactionFeeBasisPoints} - {investInfo.data?.estimatedMaxTransactionFeeBasisPoints} bps</StrongText>}
      <Tooltip text={`This is estimated based on what users paid on average in the last ${investInfo.data?.estimatedTransactionFeeIntervals} months. Network fee to send the funds into the strategy will be additional`}><span> <Info color={`${palette.white.main}`} size={12} /></span></Tooltip>
    </EstimatedFeeBpsBox>
  }

  return (
    <Layout>
      <MarginContainer>
        <CustodyNavigationWidget>
          {investInfo.isLoading || defiBalances.isLoading || strategyDetails.isLoading || assetsLoading ? (
            <>
              <Loading size={SpinnerSizeEnum.LARGE} showText={false} />
            </>
          ) : (
            <>
              <Header>
                <PageTitle>
                  Invest in {strategyDetails.data?.name}{' '}
                  {currentInvestment ? <>with Current APR of {new Percentage(currentInvestment.yield.apr).print()}</> : <></>}
                </PageTitle>
              </Header>
              <Content>
                <FormInformation>
                  <ForMoreInformation>
                    For more information about this investment strategy, please
                    <CustodyClickableText onClick={() => navigate(`/custody/invest/details/${strategyIdentifier}`)}> click here</CustodyClickableText>
                  </ForMoreInformation>
                  {investInfo.data?.nextInvestmentCutoff && (
                    <>
                      <p>You can cancel your request till <StrongText>{moment.utc(investInfo.data?.nextInvestmentCutoff).format('MMM Do')}</StrongText></p>
                      <p>You will start earning yield effective <StrongText>{moment.utc(investInfo.data?.nextEffectiveDate).format('MMM Do')}</StrongText></p>
                    </>
                  )}
                  {renderEstimatedTransactionFee()}
                  <Space />
                  <p>
                    Current account balance is <StrongText>{getPriceFormattedI(asset?.identifier, investInfo.data?.totalAmountInAsset?.amount)}</StrongText> {accountBalanceInDollars !== '-' && <>({accountBalanceInDollars})</>}
                  </p>
                  <p>
                    Current invested amount is {strategyBalance.data?.filter(d => !d.pending).map(d => <StrongText key="invested-amount">{getPriceFormattedI(d.quantity?.asset, d.quantity?.amount)} </StrongText>)}<span>({getUsDollar(currentInvestedValues.getValue())})</span>
                  </p>
                  <p>
                    Minimum investment amount is <StrongText >{getPriceFormattedI(investInfo.data?.minimumTransactionAmount.asset, investInfo.data?.minimumTransactionAmount.amount)} </StrongText>
                  </p>
                  <p>
                    Amount of {asset?.name} available for additional investment is {' '} 
                    <StrongText>{getPriceFormattedI(asset?.identifier, investInfo.data?.availableAmountInAsset?.amount)}</StrongText> {availableBalanceInDollars !== '-' && <>({availableBalanceInDollars})</>}
                   </p>
                   
                   <InfoButton 
                        onClick={() => setShowExplanation(true)}>
                        Learn More <QuestionCircle color='#7b6fe9' />
                    </InfoButton>

                      {showExplanation && (
                        <BalanceExplanationPopup
                            show={showExplanation}
                            explanation={investInfo.data?.explanation as BalanceExplanation}
                            currentBalance={investInfo.data?.totalAmountInAsset as AssetAmountPair}
                            fee={investInfo.data?.totalEstimatedFeeAmounts[0] as AssetAmountPair}
                            onClose={() => {
                                setShowExplanation(false)
                            }}
                        />
                      )}

                  
                  <FormInputBox>
                    <p>{!!parseFloat(currentInvestedValues.getValue()) && <>Additional</>} Investment Amount
                      <Tooltip text='The strategy will receive the amount minus network fee and transaction fee.'>
                          <strong>{' '}<Info color={`${palette.white.main}`} size={12} /></strong>
                      </Tooltip>
                    </p> 
                    <ValueInput 
                      assetName={asset?.name}
                      changeInvestmentAmount={changeInvestmentAmount}
                      dollarPrice={dollarPrice}
                      inputAmountSize={getTextWidth(investmentAmount.toString(), '13.3px "IBM Plex Sans"')}
                      inputAreaSize={spanWidth + valueWidth}
                      investmentAmount={investmentAmount}
                      renderMaxButton={shouldRenderMaxButton}
                      setMax={setMax}
                      spanDollarPrice={spanDollarPrice}
                      error={minError || maxError}
                    />
                  </FormInputBox>
                  {renderEstimatedFee()}
                </FormInformation>
                <RightAlignedBox>
                  <ReviewButton 
                    handleOnClick={handleShowModal}
                    isDisabled={minError || maxError || investmentAmount === '0'}
                    text='Review'
                  />
                </RightAlignedBox>
                {minError && <ErrorMessage>Your invest amount has to be equal or greater than the minimum invest value of {getPriceFormattedI(asset?.identifier, minimumAmount.getValue())}</ErrorMessage>}
                {maxError && <ErrorMessage>Exceeds maximum amount of {getPriceFormattedI(asset?.identifier, maxAmount.getValue())}</ErrorMessage>}
              </Content>
            </>
          )}
        </CustodyNavigationWidget>
        <Modal visible={showModal} onClose={() => setShowModal(false)} header='Confirmation Page'>
          <Modal.Body>
            <ModalContent>
              <p>
                Invest{' '}
                <StrongText>
                  {investmentAmount} {asset?.name}
                </StrongText>{' '}
                {dollarPrice !== '-' && <>({dollarPrice})</>} in
                <CustodyClickableText onClick={() => navigate(`/custody/invest/details/${strategyIdentifier}`)}>
                  {' '}
                  {strategyDetails.data?.name}
                </CustodyClickableText>{' '}
                {currentInvestment && <>, which offers a current APR of <StrongText>{new Percentage(currentInvestment.yield.apr).print()}</StrongText></>}
              </p>
              {investInfo.data?.nextEffectiveDate && <>
                <p>This strategy will start generating yield from <StrongText>{moment.utc(investInfo.data?.nextEffectiveDate).format('MMM Do')}</StrongText></p>
              </>}
              {renderEstimatedFee()}
              {renderEstimatedTransactionFee()}
              <p>Annual Abra Management Fee is <StrongText>{strategyDetails.data?.annualFeeBasisPoints} bps</StrongText></p>
            </ModalContent>
            { !!investInfo.data?.stepDetails?.length && 

            <AutomationStepsBox>
              <h4>Automation Steps</h4>
              <CustodyStyledTable>
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Expected Fees</th>
                    <th>Fees in USD</th>
                  </tr>
                </thead>
                <tbody>
                  {investInfo.data?.stepDetails?.map((step, index) => (
                    <tr key={"step-details-" + index}>
                      <td>{step.description}</td>
                      <td>{step.estimatedFeeAmount ? getPriceFormattedI(step.estimatedFeeAmount?.asset, step.estimatedFeeAmount?.amount) : '-'}</td>
                      <td>
                        {step.estimatedConvenienceFeeAmount
                          ? getPriceFormattedI(step.estimatedConvenienceFeeAmount?.asset, step.estimatedConvenienceFeeAmount?.amount)
                          : '-'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </CustodyStyledTable>
            </AutomationStepsBox>
            }
          </Modal.Body>
          <Modal.Footer>
            <RightAlignedBox>
              <ReviewButton 
                handleOnClick={submit} 
                isDisabled={!transactionPublicUID || createMeta.isLoading || confirmMeta.isLoading || minError}
                isLoading={isLoading}
                text='Submit'
              />
            </RightAlignedBox>
          </Modal.Footer>
        </Modal>
      </MarginContainer>
    </Layout>
  );
};

const Space = styled.div`
  margin-bottom: 6px;
`
const StrongText = styled.strong`
  font-weight: 500;
`
const FormInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const AutomationStepsBox = styled.div``;
const ModalContent = styled.div`
  margin-top: 25px;
  margin-bottom: 15px;
  display: flex;
  gap: 12px;
  flex-direction: column;
  font-size: 1rem;
`;
const RightAlignedBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CustodyClickableText = styled.span`
  cursor: pointer;
  color: #a58bf2;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
const ForMoreInformation = styled.p`
  margin-bottom: 8px;
`;

const EstimatedFeeBpsBox = styled.div`
  margin-bottom: 8px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3vh;
`;

const PageTitle = styled.h2`
  font-weight: 600;
  color: #ffffff;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: -0.5px;
  margin-bottom: 30px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const InfoButton = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 6px;
  color: #7b6fe9;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  letter-spacing: 0.51px;
  z-index: 1000;
`;

export default InvestStrategySelected;
