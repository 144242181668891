import Panel from 'components/atoms/Panel';
import React, { useRef } from 'react';
import { BalanceExplanation } from 'state/store/custodyApi';
import styled from 'styled-components';
import useAssets from 'shared/useAssets';
import { AssetAmountPair } from 'interfaces/AssetAmountPair.interface';


interface BalanceExplanationPopupProps {
  currentBalance: AssetAmountPair;
  fee: AssetAmountPair;
  explanation: BalanceExplanation;
  show: boolean;
  marginTop?: string;
  onClose: () => void;
}

const BalanceExplanationPopup: React.FC<BalanceExplanationPopupProps> = ({
  currentBalance,
  fee,
  explanation,
  show,
  marginTop,
  onClose,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const assets = useAssets();

  return (
    <PopupContainer show={show} ref={ref} marginTop={marginTop}>
      <PopupCloseButton onClick={onClose}>
        <CloseX>X</CloseX>
      </PopupCloseButton>
      <PopupTitle>Reserve Requirements</PopupTitle>
      <Panel style={{ opacity: '1' }}> 
        <ExplanationParagraph>
          Your balance available for withdrawal may be lower than the actual account balance because of reserve requirements. The details are below.   
        </ExplanationParagraph>
        <ExplanationParagraph>
          Current Account Balance: {' '} 
            <ExplanationValue> 
              {
                assets.getPriceFormattedI(
                  currentBalance?.asset,
                  currentBalance?.amount
                )
              } 
            </ExplanationValue>
        </ExplanationParagraph>
        <ExplanationTable>
          <tbody>

            <tr>
              <TableCellTitle>Management Fee Reserve</TableCellTitle>
              <TableCellValue>
                {assets.getPriceFormattedI(
                  explanation?.billingReserve?.asset,
                  explanation?.billingReserve?.amount
                )}
              </TableCellValue>
            </tr>
            <tr>
              <TableCellTitle>Network Fee Reserve</TableCellTitle>
              <TableCellValue>
                {assets.getPriceFormattedI(
                  explanation?.gasReserve?.asset,
                  explanation?.gasReserve?.amount
                )}
              </TableCellValue>
            </tr>
            <tr>
              <TableCellTitle>Hold for Transactions in Process</TableCellTitle>
              <TableCellValue>
                {assets.getPriceFormattedI(
                  explanation?.balanceHolds?.asset,
                  explanation?.balanceHolds?.amount
                )}
              </TableCellValue>
            </tr>
          </tbody>
        </ExplanationTable>

        <ExplanationParagraph>
          Because of the above reserve requirements, the max amount you can request to invest or withdraw is {' '}
          <ExplanationValue>
            {
              assets.getPriceFormattedI(
                explanation.availableBalance?.asset,
                explanation.availableBalance?.amount
              )
            }
          </ExplanationValue>. 
          Please note, this amount will be subject to an estimated network fee of {' '}
          <ExplanationValue>
            {fee.amount} {fee.asset}
          </ExplanationValue>
        </ExplanationParagraph>

        <ExplanationParagraph>
        For more guidance, please refer to <ExplanationLink href='https://support.abra.com/hc/en-us/sections/25358520640283-Top-Questions' 
                   target='_blank' 
                   rel="noreferrer">
                  frequently asked questions
                </ExplanationLink>.
        </ExplanationParagraph>
      </Panel>
    </PopupContainer>
  );
};

export default BalanceExplanationPopup;

interface ShowProps {
  show: boolean;
  marginTop?: string
}

const PopupTitle = styled.h3`
  text-align: center;
  font-weight: bolder;
`;

const PopupContainer = styled.div<ShowProps>`
  display: flex;
  box-sizing: border-box;
  border: #6c757d 1px solid;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.1s ease-in-out;
  background-color: #201f2c;
  ${({ show }) => (show ? `padding: 20px;` : `padding: 0px;`)}
  ${({ show }) => (show ? `opacity: 1;` : `opacity: 0;`)}
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 600px;
  margin-top: ${({marginTop}) => marginTop ? marginTop : 'unset'}
`;

const PopupCloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

export const CloseX = styled.button`
  color: white;
  font-weight: bolder;
  font-size: larger;
  position: absolute;
  top: 14px;
  right: 10px;
  width: 18px;
  height: 25px;
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
`;

export const ExplanationTable = styled.table`
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    padding: 15px;
    border-collapse: collapse;
`;

export const TableCell = styled.td`
    padding: 10px;
`;

export const TableCellTitle = styled.td`
    padding: 15px;
    width: 50%;
    border: 1px solid #3f4258;
    border-right: 0; 
    text-align: left;
`;

export const TableCellValue = styled.td`
    padding: 15px;
    width: 50%;
    border: 1px solid #3f4258;
    border-left: 0;
    text-align: right;
`;

export const ExplanationParagraph = styled.p`
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const ExplanationLink = styled.a`
    color: #a264f9;
`;

export const ExplanationValue = styled.span`
    font-weight: bold;
`;