import React from 'react'
import { useParams } from 'react-router-dom'
import getUsDollar from 'shared/dollarFormat'
import { BalanceYearMonth, useGetStatementDefiLoanCollateralQuery, useGetStatementDefiLoanPrincipalQuery } from 'state/store/statementsApi'
import { AssetDto } from 'state/store/api'
import useAssets from 'shared/useAssets'
import moment from 'moment'
import { Percentage } from 'lib/utils/types'
import Image from 'components/atoms/Image'
import { isNegative } from 'utils/isNegative'
import { Container, MainText, SubText, Table, TableContainer, TableRow, Td, TextGroup, Th, Title } from './sharedStyles'
import styled from 'styled-components'

const CustodyStatementDefiLoanSummary = () => {
  const { month, year } = useParams();
  const yearMonth = {year, month: month?.padStart(2, '0')}
  const date = new Date(+year!, +month! - 1);
  const collateralBalance = useGetStatementDefiLoanCollateralQuery(yearMonth);
  const principalBalance = useGetStatementDefiLoanPrincipalQuery(yearMonth);
  const lastDay = moment(date).endOf('month');
  const {getAssetByIdentifier} = useAssets();

  const collateralData = collateralBalance.data ?? []
  const principalData = principalBalance.data ?? []

  const collateralTableHeader = [              
    "Asset",
    "Network",
    "Price",
    "APY",
    "Income",
    "Period beginning Balance",
    "Period ending Balance"
  ]

  const principalTableHeader = [              
    "Asset",
    "Network",
    "Price",
    "APY",
    "Interest",
    "Period beginning Balance",
    "Period ending Balance"
  ]

  return (
    <Container>
      <Title>Collateral</Title>
      {!collateralBalance.isFetching && (
        <TableContainer>
          <Table>
            <thead>
              <TableRow>
                <Th colSpan={collateralTableHeader.length}>Data as of {lastDay.format('MMM DD, yyyy')} 11:59 UTC</Th>
              </TableRow>
              <TableRow>
                {collateralTableHeader.map(header => <Th key={header}>{header}</Th>)}
              </TableRow>
            </thead>
            <tbody>
              {collateralData?.map((record, index) => (
                <TokenSummaryRow key={index} record={record} asset={getAssetByIdentifier(record.balanceCurrency)} />
              ))}
            </tbody>
          </Table>
        </TableContainer>
      )}

      <SecondTableTitle>Borrow</SecondTableTitle>
      {!principalBalance.isFetching && (
        <TableContainer>
          <Table>
            <thead>
              <TableRow>
                <Th colSpan={principalTableHeader.length}>Data as of {lastDay.format('MMM DD, yyyy')} 11:59 UTC</Th>
              </TableRow>
              <TableRow>
                {principalTableHeader.map(header => <Th key={header}>{header}</Th>)}
              </TableRow>
            </thead>
            <tbody>
              {principalData?.map((record, index) => (
                <TokenSummaryRow key={index} record={record} asset={getAssetByIdentifier(record.balanceCurrency)} />
              ))}
            </tbody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

interface TokenSummaryRowProps {
  asset?: AssetDto;
  record: BalanceYearMonth;
}

const TokenSummaryRow: React.FC<TokenSummaryRowProps> = ({asset, record}) => {
  const {getPriceFormattedI} = useAssets();
  const noValueToDisplay = "--"
  
  return (
    <TableRow>
      <Td>
        <TokenImageBox>
          {asset ? (
            <>
              <ImageBox>
                <Image width='100%' height='100%' src={asset?.icon} />
              </ImageBox>
              <TextGroup>
                <MainText>{asset?.name}</MainText>
              </TextGroup>
            </>
          ) : (
            <TextGroup>
              <MainText>{record?.endingBalance?.asset}</MainText>
            </TextGroup>
          )}
        </TokenImageBox>
      </Td>
      <Td>
        <TextGroup>
          <MainText>{record.network}</MainText>
        </TextGroup>
      </Td>
      <Td>
        <MainText>{record?.endingPrice?.rate ? getUsDollar(record.endingPrice.rate) : noValueToDisplay}</MainText>
      </Td>
      <Td>
        <MainText>
          {record.apy ? new Percentage(`${record.apy}`).print() : noValueToDisplay}
        </MainText>
      </Td>
      <Td>
        <TextGroup>
          {record.accruedAmount ? (
            <>
              <MainText negative={isNegative(record.accruedAmount.amount)}>
                {getPriceFormattedI(record.accruedAmount.asset, record.accruedAmount.amount)}
              </MainText>
              <SubText negative={isNegative(record.accruedValue.amount)}>
                ({getUsDollar(record.accruedValue.amount)})
              </SubText>
            </>
          ) : (
            <MainText>{noValueToDisplay}</MainText>
          )}
        </TextGroup>
      </Td>
      <Td>
        <TextGroup>
          {record.startingBalance ? (
            <>
              <MainText negative={isNegative(record.startingBalance.amount)}>
                {getPriceFormattedI(record.startingBalance.asset, record.startingBalance.amount)}
              </MainText>
              <SubText negative={isNegative(record.startingValue.amount)}>
                ({getUsDollar(record.startingValue.amount)})
              </SubText>
            </>
          ) : (
            <MainText>{noValueToDisplay}</MainText>
          )}
        </TextGroup>
      </Td>
      <Td>
        <TextGroup>
          {record.endingBalance ? (
            <>
              <MainText negative={isNegative(record.endingBalance.amount)}>
                {getPriceFormattedI(record.endingBalance.asset, record.endingBalance.amount)}
              </MainText>
              <SubText negative={isNegative(record.endingValue.amount)}>
                ({getUsDollar(record.endingValue.amount)})
              </SubText>
            </>
          ) : (
            <MainText>{noValueToDisplay}</MainText>
          )}
        </TextGroup>
      </Td>
    </TableRow>
  );
};

const ImageBox = styled.div`
  max-width: calc(min(4vw, 25px));
  min-width: calc(min(4vw, 25px));
`;

const TokenImageBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6vw;
`;

const SecondTableTitle = styled(Title)`
  margin-top: 48px; // Adds significant space between tables

  @media print {
    margin-top: 32px; // Slightly reduced spacing for print layout
  }
`;

export default CustodyStatementDefiLoanSummary