import { Close } from 'assets/icons'
import DollarIcon from 'assets/icons/DollarIcon'
import GrowPlantIcon from 'assets/icons/GrowPlantIcon'
import RefreshIcon from 'assets/icons/RefreshIcon'
import ShieldAlert from 'assets/icons/ShieldAlert'
import WalletReceiveIcon from 'assets/icons/WalletReceiveIcon'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

interface Props {
  show: boolean
  closeAction: (param: boolean) => void
  identifier: string
  url?: string
  noContentTrigger: () => void
}
const StrategyDetailsPopup: React.FC<Props> = ({show, closeAction, identifier, url, noContentTrigger}) => {

  const ref = useRef<HTMLDivElement>(null)

  const strategyMapper: Record<string, JSX.Element> = {
    "lido.steth": <STETHDescription />,
    "lido.wsteth": <WSTETHDescription />,
    "omnibus.btc": <BTCDescription />,
    "omnibus.btc2": <BTCDescription2 />,
    "omnibus.eth": <ETHOmnibusDescription />,
    "omnibus.sol": <SolDescription />,
    "omnibus.sol2": <SolDescription />,
    "omnibus.usdc": <></>,
    "omnibus.test.daily": <></>,
    "omnibus.test.daily.eth":<></>,
    "omnibus.test.weekly":<></>,
    "omnibus.test.weekly.eth":<></>
  }

  const displayStrategy = () => {
    try {
      return strategyMapper[identifier];
    } catch (e) {
      noContentTrigger()
      return <></>
    }
  }


  return <StrategyDetailsContainer show={show} ref={ref}>
  <PopupCloseButton onClick={() => closeAction(false)}>
    <Close color='white' />
  </PopupCloseButton>
  <PopupTitle>Strategy Information</PopupTitle>
  <PopupDescriptionArea>
    {displayStrategy()}
  </PopupDescriptionArea>
  <PopupLearnMore>
    {url && 
    <StrategyLink target='_blank' rel='noopener noreferrer'
    href={url}>Learn More</StrategyLink>
    }
  </PopupLearnMore>
</StrategyDetailsContainer>
}

const STETHDescription = () => (
  <>
    <PopupDescription>
      <IconBox><RefreshIcon /></IconBox>
      <p>
        When you stake your <strong>ETH with Lido</strong>, you <strong>get wstETH</strong> (a token that represents your stake).
      </p>
    </PopupDescription>
    <PopupDescription>
      <IconBox>
        <DollarIcon />
      </IconBox>
      <p>
        There is <strong>no minimum</strong> to stake with Lido.
      </p>
    </PopupDescription>
    <PopupDescription>
      <IconBox>
        <GrowPlantIcon />
      </IconBox>
      <p>
        You'll be eligible to <strong>get rewards right away.</strong> The amount of wstETH in your account may change daily to reflect rewards you receive from
        Lido.
      </p>
    </PopupDescription>
    <PopupDescription>
      <IconBox>
        <WalletReceiveIcon />
      </IconBox>
      <p>
        <strong>Exchange your wstETH for ETH through a direct withdrawal or swapping.</strong> The amount withdrawable via a swap is a dynamic rate that may
        differ from a direct withdrawal.
      </p>
    </PopupDescription>
    <PopupDescription>
      <IconBox>
        <ShieldAlert />
      </IconBox><p>Staking does not guarantee rewards, and involves risks including a loss of funds.</p>
    </PopupDescription>
  </>
);

const ETHOmnibusDescription = () => (
  <>
    <PopupDescription>
      <IconBox><RefreshIcon /></IconBox>
      <p>
        Additional yield is generated periodically through DeFi rewards and points
      </p>
    </PopupDescription>
    <PopupDescription>
      <IconBox>
        <DollarIcon />
      </IconBox>
      <p>
        Withdrawal typically takes 5 - 10 days depending on liquidity and underlying DeFi market conditions, may take longer subject to those conditions.
      </p>
    </PopupDescription>
    <PopupDescription>
      <IconBox>
        <GrowPlantIcon />
      </IconBox>
      <p>
      There is no income accrual during the withdrawal waiting period.
      </p>
    </PopupDescription>
  </>
);

const WSTETHDescription = () => (
  <>
    <PopupDescription>
      <IconBox><RefreshIcon /></IconBox>
      <p>
      wstETH (wrapped stETH) is a non-rebasing version of stETH, wstETH's price denominated in stETH changes instead.
      </p>
    </PopupDescription>
    <PopupDescription>
      <IconBox>
        <DollarIcon />
      </IconBox>
      <p>
      The wstETH balance can only be changed upon transfers, minting, and burning.
      </p>
    </PopupDescription>
    <PopupDescription>
      <IconBox>
        <GrowPlantIcon />
      </IconBox>
      <p>
      At any given time, anyone holding wstETH can convert any amount of it to stETH at a fixed rate, and vice versa.
      </p>
    </PopupDescription>
    <PopupDescription>
      <IconBox>
        <WalletReceiveIcon />
      </IconBox>
      <p>
      Normally, the rate gets updated once a day, when stETH undergoes a rebase.
      </p>
    </PopupDescription>
  </>
);

const BTCDescription = () => (
  <>
    <PopupDescription>
      <IconBox><RefreshIcon /></IconBox>
      <p>
      The BTC yield strategy provides investors with directional exposure to BTC beta and aims to increase exposure to BTC through various BTC-based yield strategies in the digital asset markets.
      </p>
    </PopupDescription>
    <PopupDescription>
      <IconBox>
        <DollarIcon />
      </IconBox>
      <p>
      These strategies may include 
      <p>I. basis trades </p>
      <p>II. automated market making (aka liquidity provisioning) in the DeFi markets and/or </p>
      <p>III. supplying or borrowing liquidity on DeFi platforms.</p>
      
      
      
      </p>
    </PopupDescription>
    <PopupDescription>
      <IconBox>
        <GrowPlantIcon />
      </IconBox>
      <p>
      Returns will be denominated in units of BTC. Please note, this strategy only offers monthly redemptions. 
      </p>
    </PopupDescription>
  </>
);

const BTCDescription2 = () => (
  <>
    <PopupDescription>
      <IconBox><RefreshIcon /></IconBox>
      <p>
        This strategy generates yield by borrowing a basket of PoS altcoins to earn staking rewards.
      </p>
    </PopupDescription>
    <PopupDescription>
      <IconBox>
        <DollarIcon />
      </IconBox>
      <p>
        This strategy generates yield by borrowing a basket of PoS altcoins to earn staking rewards, which are then converted into units of BTC. The altcoins are hedged so that the strategy does not have exposure to the beta of those assets, other than with respect to the yield before it is converted into BTC.  This strategy aims to generate relatively consistent yield across all market environments.
      </p>
    </PopupDescription>
    <PopupDescription>
      <IconBox>
        <GrowPlantIcon />
      </IconBox>
      <p>
        Please note, this strategy only offers monthly redemptions. Due to the time required to unstake some of the altcoins, it could take up to 35 days to get your funds back.
      </p>
    </PopupDescription>
  </>
);

const SolDescription = () => (
  <>
    <PopupDescription>
      <IconBox><RefreshIcon /></IconBox>
      <p>
        The SOL staking strategy provides investors with exposure to SOL and provides additional income through liquid staking of SOL.
      </p>
    </PopupDescription>
    <PopupDescription>
      <IconBox>
        <DollarIcon />
      </IconBox>
      <p>
        Solana staking involves delegating SOL tokens to a selected validator.
      </p>
    </PopupDescription>
    <PopupDescription>
      <IconBox>
        <GrowPlantIcon />
      </IconBox>
      <p>
      Validators use these as a bond for securing and validating the Solana blockchain, distributing rewards to you every epoch.
      </p>
    </PopupDescription>
    <PopupDescription>
      <IconBox>
        <WalletReceiveIcon />
      </IconBox>
      <p>
      Please note, this strategy only offers weekly redemptions. 
      </p>
    </PopupDescription>
  </>
);
const PopupTitle = styled.h3`
  text-align: center;
`;
const IconBox = styled.div`
  display: flex;
  min-height: 30px;
  min-width: 30px;
  max-height: 30px;
  max-width: 30px;
`
const PopupDescriptionArea = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 20px;
`;
interface ShowProps {
  show: boolean
}
const StrategyDetailsContainer = styled.div<ShowProps>`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
  background-color: #2b293e;
  ${({ show }) => (show ? `width: 400px;` : `width: 0px;`)}
  ${({ show }) => (show ? `padding: 10px;` : `padding: 0px;`)}
  ${({ show }) => (show ? `opacity: 1;` : `opacity: 0;`)}
  position: absolute;
  right: 10px;
  overflow: hidden;
  top: 10px;
`;
const PopupCloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;
const PopupLearnMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StrategyLink = styled.a`
  color: #a399f6;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0.51px;
`;
const PopupDescription = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 30px;
`;

export default StrategyDetailsPopup
