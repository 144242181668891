import React, { useEffect } from 'react';
import { MarginContainer } from '../Custody';
import CustodyNavigationWidget from '../CustodyNavigationWidget'
import Layout from 'components/templates/Layout'
import useCustodyRedirect from '../extraCustodyHooks/useCustodyRedirect'
import StrategyDetailsCard from './StrategyDetailsCard'
import StrategyDetailsBalance from './StrategyDetailsBalance'
import StrategyDetailsPendingActivity from './StrategyDetailsPendingActivity'
import StrategyDetailsTransactionDetails from './StrategyDetailsTransactionDetails'
import useStrategy from './useStrategy'
import { useParams } from 'react-router-dom'
import useInvest from './useInvest'
import Loading from 'components/atoms/Loading'
import { SpinnerSizeEnum } from 'components/atoms/Loading/Loading'
import Widget from 'components/templates/Widget/Widget';

const InvestmentStrategiesSummary = () => {

  const { strategyIdentifier } = useParams();
  const { strategyDetails, loadInvestTransactionsInfo, investInfo } = useStrategy(strategyIdentifier ?? '');
  const { defiBalances } = useInvest()

  useEffect(() => {
    if (strategyIdentifier) {
      loadInvestTransactionsInfo();
    }
  }, [strategyIdentifier, loadInvestTransactionsInfo]);

  useCustodyRedirect()
  return (
    <Layout>
      <MarginContainer>
        <CustodyNavigationWidget>
          { strategyDetails.isLoading || investInfo.isLoading || defiBalances.isLoading ?
          <>
            <Loading size={SpinnerSizeEnum.LARGE} showText={false}/>
          </>
          :
          <>
            <MarginContainer>
              <Widget>
                <StrategyDetailsCard />
              </Widget>
            </MarginContainer>
            <MarginContainer>
            </MarginContainer>
              <Widget>
                <StrategyDetailsBalance />
              </Widget>
            <MarginContainer>
              <Widget>
                <StrategyDetailsPendingActivity />
              </Widget>
            </MarginContainer>
            <MarginContainer>
              <Widget>
                <StrategyDetailsTransactionDetails />
              </Widget>
            </MarginContainer>
          </>
          }
        </CustodyNavigationWidget>
      </MarginContainer>
    </Layout>
  );
};


export default InvestmentStrategiesSummary;
